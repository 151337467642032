import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import DivClick from '../DivClick';

/*
TextField : Display an input box

PARAMS: maxLength : (int)     Url of the image (ex: 'http://whatever.com/img.png')
        hint : (String)     Width of the image default 100% (ex: '200px')
        label : (boolean) Disable the image (grayout and not onClick action)
        type :
        uppercase:
        canSee:
        mandatory:
        values:
        autofocus:
        showCheck:
        showTitleError:
        validationMsg:
        validator:
        labelPosition:
        readOnly
        onChange (function)
        onEnterPress (function)        
*/

function TextField(props) {

  const TYPE_PASSWORD = 'password';
  const TYPE_TEXT = 'text';
  const TYPE_DIGIT = 'digit';
  const TYPE_2DECIMALS = '2decimals';
  const TYPE_2DECIMALS_WITH_MINUS = '2decimalsWithMinus';
  const TYPE_DIGIT_WITH_MINUS = 'digitWithMinus';
  const TYPE_TEXTAREA = 'textarea';

  const TRUE = 'true';
  const AUTOFOCUS = 'autofocus';
  const EMPTY = '';


  const rows = props.rows ? props.rows : 5;

  const theType = props.type ? (props.type === TYPE_PASSWORD ? TYPE_PASSWORD : (props.type === TYPE_TEXTAREA ? TYPE_TEXTAREA : TYPE_TEXT)) : TYPE_TEXT;
  const maxLength = props.maxLength ? props.maxLength : 2000;
  const hint = props.hint ? props.hint : EMPTY;
  const label = props.label ? props.label : null;

  var theH = (theType === TYPE_TEXTAREA) ? undefined : (props.height ? props.height : '40px');

  const height = theH;
  const checkMarginTop = props.checkMarginTop ? props.checkMarginTop : '5px';
  const border = props.border ? props.border : '1px solid #707070';

  const onlyAllowDigit = props.type ? ((props.type === TYPE_DIGIT) || (props.type === TYPE_DIGIT_WITH_MINUS)) : false;
  const onlyAllowDigitWithMinus = props.type ? ((props.type === TYPE_DIGIT_WITH_MINUS)) : false;

  const only2Digits = props.type ? ((props.type === TYPE_2DECIMALS) || (props.type === TYPE_2DECIMALS_WITH_MINUS)) : false;
  const only2DigitsWithMinus = props.type ? ((props.type === TYPE_2DECIMALS_WITH_MINUS)) : false;

  const uppercase = props.uppercase ? (props.uppercase === true) : false;

  const [type, setType] = useState(theType);
  const canSee = (props.canSee === TRUE) ? (props.type !== TYPE_PASSWORD ? false : true) : false;
  const isMandatory = (props.mandatory === TRUE) ? true : false;

  const [hasError, setHasError] = useState((props.values && props.values[1] != null) ? !props.values[1] : null);

  const [value, setValue] = useState(props.values ? props.values[0] : EMPTY);
  const [autofocus] = useState((props.autofocus === TRUE) ? AUTOFOCUS : EMPTY);

  const [displayCheck] = useState(props.showCheck === TRUE ? true : false);

  const [readOnly] = useState(props.readOnly === true ? true : false);

  const [displayTitleError] = useState(props.showTitleError === TRUE ? true : false);

  useEffect(() => {
    if (props.validateOnStart === true) {
      performValidation(value);
    }
  }, // eslint-disable-next-line
    []);

  function performValidation(newValue) {
    if (props.validator) {
      var isValid = validateValue(newValue);
      if (props.validatorInformer) {
        props.validatorInformer(isValid, newValue)
      }
      setHasError(!isValid)
    }
  }
  /*************************************************************
   * newValue(newValue)
   *************************************************************/
  function newValue(newValue) {

    if (props.onChange) {
      var newV = props.onChange(newValue);
      if (newV !== undefined) {
        newValue = newV;
      }
    }

    if (only2Digits) {
      if (only2DigitsWithMinus) {
        let tempo = newValue.replace(/[^0-9.]/g, '');
        if (newValue.startsWith('-')) {
          tempo = '-' + tempo;
        }
        newValue = tempo;
      } else {
        newValue = newValue.replace(/[^0-9.]/g, '');
      }

      if (newValue.indexOf('.') > -1) {
        let start = newValue.substring(0, newValue.indexOf('.'));
        let end = '';

        let interval = (newValue.length - 1) - newValue.indexOf('.');

        if (interval >= 0) {
          if (interval > 2) {
            interval = 2;
          }
          end = newValue.substring(newValue.indexOf('.'), newValue.indexOf('.') + (interval + 1));
        }

        newValue = start + end;
      }
    }

    if (onlyAllowDigit) {
      if (onlyAllowDigitWithMinus) {
        let tempo = newValue.replace(/\D/g, '');
        if (newValue.startsWith('-')) {
          tempo = '-' + tempo;
        }
        newValue = tempo;
      } else {
        newValue = newValue.replace(/\D/g, '');
      }

    }

    if (uppercase) {
      newValue = newValue.toUpperCase();
    }

    setValue(newValue);
    performValidation(newValue)
  }

  /*************************************************************
   * toggleType()
   *************************************************************/
  function toggleType() {
    setType((type === TYPE_TEXT) ? TYPE_PASSWORD : TYPE_TEXT);
  }


  /*************************************************************
   * validateValue(toCheck) {
   *************************************************************/
  function validateValue(toCheck) {
    var trimmedValue = toCheck.trim();

    var toReturn = true;
    if (props.validator) {
      if (trimmedValue.length > 0) {
        if (!props.validator(trimmedValue)) {
          toReturn = false;
        }
      } else {
        toReturn = !isMandatory;
      }
    }

    return toReturn;
  }

  function onKeyDown(e) {
    if (props.onEnterPress) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        props.onEnterPress(value);
      }
    }
  }

  function getTopTitleStyle() {
    var toReturn = style.topTitle;
    if (props.labelPosition === 'center') {
      toReturn = style.topTitleCenter;
    }
    return toReturn;
  }

  function getGridStyle() {
    if (canSee && displayCheck) {
      return style.borderMenu_search_grid_3
    } else if (canSee || displayCheck) {
      return style.borderMenu_search_grid_2
    } else {
      return style.borderMenu_search_grid_1
    }
  }

  try {
    return (
      <div className={style.baseGrid}>
        {label &&
          <div className={`${getTopTitleStyle()} ${props.topTitleStyle ? props.topTitleStyle : null}`}>
            {label} <span className={style.mandatory}>{(isMandatory) ? ('*') : EMPTY}</span> {(hasError && displayTitleError) ? (' - ') : EMPTY}<span className={style.error}>{(hasError && displayTitleError) ? (props.validationMsg) : EMPTY}</span>
          </div>
        }
        {(!label && hasError && displayTitleError) &&
          <div className={`${getTopTitleStyle()}`}>
            {props.validationMsg}
          </div>
        }
        <div className={`${style.borderMenu_search_grid2}`}>
          <div style={{ height: height, border: border }} className={props.styleBaseUI ? props.styleBaseUI : ((type === TYPE_TEXTAREA) ? (readOnly ? style.baseUITA_ro : style.baseUITA) : (readOnly ? style.baseUI_ro : style.baseUI))}>
            <div className={`${getGridStyle()}`}>
              <div className={(type === TYPE_TEXTAREA) ? (readOnly ? style.inputTA_ro : style.inputTA) : (readOnly ? style.input_ro : style.input)}>
                {type !== TYPE_TEXTAREA &&
                  <>
                    {autofocus &&
                      <input autoComplete="one-time-code" readOnly={readOnly} onKeyDown={(e) => onKeyDown(e)} value={value} type={type} onChange={(e) => newValue(e.target.value)} maxLength={maxLength} className={props.styleCustom ? props.styleCustom : (readOnly ? style.input_tag_ro : style.input_tag)} placeholder={hint} autoFocus />
                    }
                    {!autofocus &&
                      <input autoComplete="one-time-code" readOnly={readOnly} onKeyDown={(e) => onKeyDown(e)} value={value} type={type} onChange={(e) => newValue(e.target.value)} maxLength={maxLength} className={props.styleCustom ? props.styleCustom : (readOnly ? style.input_tag_ro : style.input_tag)} placeholder={hint} />
                    }
                  </>
                }

                {type === TYPE_TEXTAREA &&
                  <>
                    {autofocus && !readOnly &&
                      <textarea autoComplete="one-time-code" rows={rows} onKeyDown={(e) => onKeyDown(e)} value={value} onChange={(e) => newValue(e.target.value)} maxLength={maxLength} className={style.input_tagTextArea} placeholder={hint} autoFocus />
                    }
                    {!autofocus && !readOnly &&
                      <textarea autoComplete="one-time-code" rows={rows} onKeyDown={(e) => onKeyDown(e)} value={value} onChange={(e) => newValue(e.target.value)} maxLength={maxLength} className={style.input_tagTextArea} placeholder={hint} />
                    }
                    {autofocus && readOnly &&
                      <textarea readOnly autoComplete="one-time-code" rows={rows} onKeyDown={(e) => onKeyDown(e)} value={value} onChange={(e) => newValue(e.target.value)} maxLength={maxLength} className={style.input_tagTextAreaRO} placeholder={hint} autoFocus />
                    }
                    {!autofocus && readOnly &&
                      <textarea readOnly autoComplete="one-time-code" rows={rows} onKeyDown={(e) => onKeyDown(e)} value={value} onChange={(e) => newValue(e.target.value)} maxLength={maxLength} className={style.input_tagTextAreaRO} placeholder={hint} />
                    }
                  </>
                }

              </div>
              {(displayCheck && hasError === null) && <div></div>}
              {(displayCheck && hasError !== null && hasError === true) &&
                <div style={{ marginTop: checkMarginTop }} className={style.checkNo}>
                  <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                </div>
              }
              {(displayCheck && ((hasError !== null) && (hasError === false))) &&
                <div style={{ marginTop: checkMarginTop }} className={style.check}>
                  <i className="fa fa-check fa-2x" aria-hidden="true"></i>
                </div>
              }
              {(canSee) &&
                <DivClick className={style.image} onClick={toggleType}>
                  {type === TYPE_PASSWORD &&
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  }
                  {type !== TYPE_PASSWORD &&
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  }
                </DivClick>
              }
            </div>
          </div>
        </div>
      </div >
    );

  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default TextField

