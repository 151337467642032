export const MOBILE_SCREEN_SIZE_WIDTH_PIXEL = 1310;

export const ROUTE_FORGOT_PASSWORD = '/auth/forgot-password';
export const ROUTE_FORGOT_PASSWORD_BACKUP = '/forgot-password';

export const ROUTE_SLASH = '/slash';
export const ROUTE_LOGIN = '/auth/login';
export const ROUTE_LOGIN_BACKUP = '/login';
export const ROUTE_REGISTER = '/auth/register';
export const ROUTE_REFER_A_FRIEND = '/refer-a-friend';
export const ROUTE_CONTEST = '/contest';
export const ROUTE_LOYALTY_DAILY = '/daily';
export const ROUTE_LOYALTY_SHOP = '/shop';
export const ROUTE_LOYALTY_PROGRESS = '/progress';
export const ROUTE_ABOUT = '/public/about';
export const ROUTE_BETTING_RULES = '/public/betting-rules';
export const ROUTE_BONUS_TERMS = '/public/bonusterms';
export const ROUTE_CONTACT = '/public/contact';
export const ROUTE_FAQ = '/public/FAQ';
export const ROUTE_PRIVACY_POLICY = '/public/privacy-policy';
export const ROUTE_TERMS_AND_CONDITIONS = '/public/terms-and-conditions';
export const ROUTE_404 = '/auth/404';
export const ROUTE_500 = '/auth/500';
export const ROUTE_VERIFICATION = '/auth/verification';
export const ROUTE_VERIFICATION_BACKUP = '/verification';
export const ROUTE_RESET_PASSWORD = '/auth/reset-password/:hash';
export const ROUTE_CHANGE_EMAIL = '/auth/change-email/:hash';
export const ROUTE_REGISTER_WITH_HASH = '/auth/register/:hash/:option?';
export const ROUTE_REGISTER_LANDING = '/auth/registerlanding/:hash?';
export const ROUTE_REGISTER_FROM_STREAMER = '/streamer/:hash?';
export const ROUTE_REGISTER_REMINDER = '/auth/reminder'
export const ROUTE_USER_MANAGEMENT = '/admin/usermanagement';
export const ROUTE_CONTEST_WINNERS = '/admin/contestwinners';
export const ROUTE_CONTEST_WINNERS_DETAIL = '/admin/contestwinnersdetail/:id';
export const ROUTE_CONTEST_WINNERS_DETAIL_BASE = '/admin/contestwinnersdetail/';
export const ROUTE_SMS = '/admin/sms';
export const ROUTE_SMS_DETAIL = '/admin/smsdetail/:id';
export const ROUTE_SMS_DETAIL_BASE = '/admin/smsdetail/';
export const ROUTE_INFO_POPUP = '/admin/infoPopup';
export const ROUTE_INFO_POPUP_DETAIL = '/admin/infoPopupDetail/:id';
export const ROUTE_INFO_POPUP_DETAIL_BASE = '/admin/infoPopupDetail/';
export const ROUTE_ADMIN_USERDATA = '/admin/userdata/:id';
export const ROUTE_ADMIN_USERDATA_BASE = '/admin/userdata/';
export const ROUTE_ADMIN_STREAMERS = '/admin/streamers';
export const ROUTE_ADMIN_PROMOTIONS = '/admin/proms';
export const ROUTE_ADMIN_PROMOTION_DETAIL = '/admin/prom/:id';
export const ROUTE_ADMIN_PROMOTION_DETAIL_BASE = '/admin/prom/';
export const ROUTE_ADMIN_PROMOTION_NEW = '/admin/newprom';

export const ROUTE_ADMIN_GAMES = '/admin/games';
export const ROUTE_MAINTENANCE = '/maintenance';
export const ROUTE_ADMIN_GIVEAWAYS = '/admin/giveaways';

export const ROUTE_RESPONSBILE_GAMING = '/rg';
export const ROUTE_RESPONSBILE_GAMING_DEPOSIT_LINIT = '/rg/depositlimit';
export const ROUTE_RESPONSBILE_GAMING_LOSSLIMIT = '/rg/losslimit';
export const ROUTE_RESPONSBILE_GAMING_TAKE_A_BREAK = '/rg/takeabreak';
export const ROUTE_RESPONSBILE_GAMING_ACTIVITY_CHECK = '/rg/activitycheck';
export const ROUTE_RESPONSBILE_GAMING_SELF_EXCLUSION = '/rg/selfexclusion';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PLAY_GAME = '/public/casino/game/:id?';
export const ROUTE_PLAY_GAME_BASE = '/public/casino/game/';
export const ROUTE_PLAY_GAME_BACKUP = '/playgame/:id?';
export const ROUTE_PLAY_GAME_BACKUP_BASE = '/playgame/';
export const ROUTE_CASHIER = '/cashier';
export const ROUTE_STREAMING_DEAL = '/streaming-deal';
export const ROUTE_GIVEAWAYS = '/giveaways';
export const ROUTE_LOGOUT = '/auth/logout';
export const ROUTE_USERLOGOUT = '/logout';
export const ROUTE_440 = '/logout440';
export const ROUTE_AFF_USERS = '/affiliates';
export const ROUTE_AFF_COMMISSIONS = '/commissions';
export const ROUTE_CASINO = '/public/casino';
export const ROUTE_LIVE_CASINO = '/public/casino';  //TO CHANGE
export const ROUTE_HOME = '/';
export const ROUTE_HOME_BACKUP = '/Home';
export const ROUTE_HOME_FREESPIN = '/HomeFreeSpin';
export const ROUTE_REGISTER_BACKUP = '/Register';
export const ROUTE_HOME_CASINO = '/casino';
export const ROUTE_HOME_LIVEDEALER = '/livedealer';

export const LOGIN_FAILURE_BAD_USER_OR_PASSWORD = 'LOGIN_FAILURE_BAD_USER_OR_PASSWORD'
export const LOGIN_FAILURE_EMAIL_NOT_VERIFY = 'LOGIN_FAILURE_EMAIL_NOT_VERIFY'
export const LOGIN_FAILURE_BREAK = 'LOGIN_FAILURE_BREAK'

export const DEFAULT_LANGUAGE = 'en-US'
export const LANGUAGE_ENGLISH = 'en-US'
export const LANGUAGE_FRENCH = 'fr-FR'
export const LANGUAGE_PORTUGUESE = 'pt-BR'

export const STRING_EMPTY = ''
export const PIXEL = 'px'
export const DDMMYYYY = 'DD/MM/YYYY';

export const countryPhoneCodes = [
    { code: '93', label: 'Afghanistan (+93)' },
    { code: '355', label: 'Albania (+355)' },
    { code: '213', label: 'Algeria (+213)' },
    { code: '1684', label: 'American Samoa (+1684)' },
    { code: '376', label: 'Andorra (+376)' },
    { code: '244', label: 'Angola (+244)' },
    { code: '1264', label: 'Anguilla (+1264)' },
    { code: '1268', label: 'Antigua and Barbuda (+1268)' },
    { code: '54', label: 'Argentina (+54)' },
    { code: '374', label: 'Armenia (+374)' },
    { code: '297', label: 'Aruba (+297)' },
    { code: '61', label: 'Australia (+61)' },
    { code: '43', label: 'Austria (+43)' },
    { code: '994', label: 'Azerbaijan (+994)' },
    { code: '1242', label: 'Bahamas (+1242)' },
    { code: '973', label: 'Bahrain (+973)' },
    { code: '880', label: 'Bangladesh (+880)' },
    { code: '1246', label: 'Barbados (+1246)' },
    { code: '375', label: 'Belarus (+375)' },
    { code: '32', label: 'Belgium (+32)' },
    { code: '501', label: 'Belize (+501)' },
    { code: '229', label: 'Benin (+229)' },
    { code: '1441', label: 'Bermuda (+1441)' },
    { code: '975', label: 'Bhutan (+975)' },
    { code: '591', label: 'Bolivia (+591)' },
    { code: '387', label: 'Bosnia and Herzegovina (+387)' },
    { code: '267', label: 'Botswana (+267)' },
    { code: '55', label: 'Brazil (+55)' },
    { code: '673', label: 'Brunei (+673)' },
    { code: '359', label: 'Bulgaria (+359)' },
    { code: '226', label: 'Burkina Faso (+226)' },
    { code: '257', label: 'Burundi (+257)' },
    { code: '855', label: 'Cambodia (+855)' },
    { code: '237', label: 'Cameroon (+237)' },
    { code: '1', label: 'Canada (+1)' },
    { code: '238', label: 'Cape Verde (+238)' },
    { code: '1345', label: 'Cayman Islands (+1345)' },
    { code: '236', label: 'Central African Republic (+236)' },
    { code: '235', label: 'Chad (+235)' },
    { code: '56', label: 'Chile (+56)' },
    { code: '86', label: 'China (+86)' },
    { code: '57', label: 'Colombia (+57)' },
    { code: '269', label: 'Comoros (+269)' },
    { code: '243', label: 'Congo [DRC] (+243)' },
    { code: '242', label: 'Congo [Republic] (+242)' },
    { code: '682', label: 'Cook Islands (+682)' },
    { code: '506', label: 'Costa Rica (+506)' },
    { code: '225', label: 'Côte d’Ivoire (+225)' },
    { code: '385', label: 'Croatia (+385)' },
    { code: '53', label: 'Cuba (+53)' },
    { code: '599', label: 'Curaçao (+599)' },
    { code: '357', label: 'Cyprus (+357)' },
    { code: '420', label: 'Czech Republic (+420)' },
    { code: '45', label: 'Denmark (+45)' },
    { code: '253', label: 'Djibouti (+253)' },
    { code: '1767', label: 'Dominica (+1767)' },
    { code: '1809', label: 'Dominican Republic (+1809)' },
    { code: '593', label: 'Ecuador (+593)' },
    { code: '20', label: 'Egypt (+20)' },
    { code: '503', label: 'El Salvador (+503)' },
    { code: '240', label: 'Equatorial Guinea (+240)' },
    { code: '291', label: 'Eritrea (+291)' },
    { code: '372', label: 'Estonia (+372)' },
    { code: '251', label: 'Ethiopia (+251)' },
    { code: '298', label: 'Faroe Islands (+298)' },
    { code: '679', label: 'Fiji (+679)' },
    { code: '358', label: 'Finland (+358)' },
    { code: '33', label: 'France (+33)' },
    { code: '689', label: 'French Polynesia (+689)' },
    { code: '241', label: 'Gabon (+241)' },
    { code: '220', label: 'Gambia (+220)' },
    { code: '995', label: 'Georgia (+995)' },
    { code: '49', label: 'Germany (+49)' },
    { code: '233', label: 'Ghana (+233)' },
    { code: '350', label: 'Gibraltar (+350)' },
    { code: '30', label: 'Greece (+30)' },
    { code: '299', label: 'Greenland (+299)' },
    { code: '1473', label: 'Grenada (+1473)' },
    { code: '590', label: 'Guadeloupe (+590)' },
    { code: '1671', label: 'Guam (+1671)' },
    { code: '502', label: 'Guatemala (+502)' },
    { code: '44', label: 'Guernsey (+44)' },
    { code: '224', label: 'Guinea (+224)' },
    { code: '245', label: 'Guinea-Bissau (+245)' },
    { code: '592', label: 'Guyana (+592)' },
    { code: '509', label: 'Haiti (+509)' },
    { code: '504', label: 'Honduras (+504)' },
    { code: '852', label: 'Hong Kong (+852)' },
    { code: '36', label: 'Hungary (+36)' },
    { code: '354', label: 'Iceland (+354)' },
    { code: '91', label: 'India (+91)' },
    { code: '62', label: 'Indonesia (+62)' },
    { code: '98', label: 'Iran (+98)' },
    { code: '964', label: 'Iraq (+964)' },
    { code: '353', label: 'Ireland (+353)' },
    { code: '44', label: 'Isle of Man (+44)' },
    { code: '972', label: 'Israel (+972)' },
    { code: '39', label: 'Italy (+39)' },
    { code: '1876', label: 'Jamaica (+1876)' },
    { code: '81', label: 'Japan (+81)' },
    { code: '44', label: 'Jersey (+44)' },
    { code: '962', label: 'Jordan (+962)' },
    { code: '7', label: 'Kazakhstan (+7)' },
    { code: '254', label: 'Kenya (+254)' },
    { code: '686', label: 'Kiribati (+686)' },
    { code: '965', label: 'Kuwait (+965)' },
    { code: '383', label: 'Kosovo (+383)' },
    { code: '996', label: 'Kyrgyzstan (+996)' },
    { code: '856', label: 'Laos (+856)' },
    { code: '371', label: 'Latvia (+371)' },
    { code: '961', label: 'Lebanon (+961)' },
    { code: '266', label: 'Lesotho (+266)' },
    { code: '231', label: 'Liberia (+231)' },
    { code: '218', label: 'Libya (+218)' },
    { code: '423', label: 'Liechtenstein (+423)' },
    { code: '370', label: 'Lithuania (+370)' },
    { code: '352', label: 'Luxembourg (+352)' },
    { code: '853', label: 'Macau (+853)' },
    { code: '261', label: 'Madagascar (+261)' },
    { code: '265', label: 'Malawi (+265)' },
    { code: '60', label: 'Malaysia (+60)' },
    { code: '960', label: 'Maldives (+960)' },
    { code: '223', label: 'Mali (+223)' },
    { code: '356', label: 'Malta (+356)' },
    { code: '692', label: 'Marshall Islands (+692)' },
    { code: '596', label: 'Martinique (+596)' },
    { code: '222', label: 'Mauritania (+222)' },
    { code: '230', label: 'Mauritius (+230)' },
    { code: '52', label: 'Mexico (+52)' },
    { code: '691', label: 'Micronesia (+691)' },
    { code: '373', label: 'Moldova (+373)' },
    { code: '377', label: 'Monaco (+377)' },
    { code: '976', label: 'Mongolia (+976)' },
    { code: '382', label: 'Montenegro (+382)' },
    { code: '1664', label: 'Montserrat (+1664)' },
    { code: '212', label: 'Morocco (+212)' },
    { code: '258', label: 'Mozambique (+258)' },
    { code: '95', label: 'Myanmar [Burma] (+95)' },
    { code: '264', label: 'Namibia (+264)' },
    { code: '674', label: 'Nauru (+674)' },
    { code: '977', label: 'Nepal (+977)' },
    { code: '31', label: 'Netherlands (+31)' },
    { code: '687', label: 'New Caledonia (+687)' },
    { code: '64', label: 'New Zealand (+64)' },
    { code: '505', label: 'Nicaragua (+505)' },
    { code: '227', label: 'Niger (+227)' },
    { code: '234', label: 'Nigeria (+234)' },
    { code: '850', label: 'North Korea (+850)' },
    { code: '389', label: 'North Macedonia (+389)' },
    { code: '47', label: 'Norway (+47)' },
    { code: '968', label: 'Oman (+968)' },
    { code: '92', label: 'Pakistan (+92)' },
    { code: '680', label: 'Palau (+680)' },
    { code: '970', label: 'Palestine (+970)' },
    { code: '507', label: 'Panama (+507)' },
    { code: '675', label: 'Papua New Guinea (+675)' },
    { code: '595', label: 'Paraguay (+595)' },
    { code: '51', label: 'Peru (+51)' },
    { code: '63', label: 'Philippines (+63)' },
    { code: '48', label: 'Poland (+48)' },
    { code: '351', label: 'Portugal (+351)' },
    { code: '1787', label: 'Puerto Rico (+1787)' },
    { code: '974', label: 'Qatar (+974)' },
    { code: '262', label: 'Réunion (+262)' },
    { code: '40', label: 'Romania (+40)' },
    { code: '7', label: 'Russia (+7)' },
    { code: '250', label: 'Rwanda (+250)' },
    { code: '1869', label: 'Saint Kitts and Nevis (+1869)' },
    { code: '1758', label: 'Saint Lucia (+1758)' },
    { code: '590', label: 'Saint Martin (+590)' },
    { code: '1784', label: 'Saint Vincent and the Grenadines (+1784)' },
    { code: '685', label: 'Samoa (+685)' },
    { code: '378', label: 'San Marino (+378)' },
    { code: '239', label: 'São Tomé and Príncipe (+239)' },
    { code: '966', label: 'Saudi Arabia (+966)' },
    { code: '221', label: 'Senegal (+221)' },
    { code: '381', label: 'Serbia (+381)' },
    { code: '248', label: 'Seychelles (+248)' },
    { code: '232', label: 'Sierra Leone (+232)' },
    { code: '65', label: 'Singapore (+65)' },
    { code: '599', label: 'Sint Maarten (+599)' },
    { code: '421', label: 'Slovakia (+421)' },
    { code: '386', label: 'Slovenia (+386)' },
    { code: '677', label: 'Solomon Islands (+677)' },
    { code: '252', label: 'Somalia (+252)' },
    { code: '27', label: 'South Africa (+27)' },
    { code: '82', label: 'South Korea (+82)' },
    { code: '211', label: 'South Sudan (+211)' },
    { code: '34', label: 'Spain (+34)' },
    { code: '94', label: 'Sri Lanka (+94)' },
    { code: '249', label: 'Sudan (+249)' },
    { code: '597', label: 'Suriname (+597)' },
    { code: '268', label: 'Swaziland (+268)' },
    { code: '46', label: 'Sweden (+46)' },
    { code: '41', label: 'Switzerland (+41)' },
    { code: '963', label: 'Syria (+963)' },
    { code: '886', label: 'Taiwan (+886)' },
    { code: '992', label: 'Tajikistan (+992)' },
    { code: '255', label: 'Tanzania (+255)' },
    { code: '66', label: 'Thailand (+66)' },
    { code: '670', label: 'Timor-Leste (+670)' },
    { code: '228', label: 'Togo (+228)' },
    { code: '676', label: 'Tonga (+676)' },
    { code: '1868', label: 'Trinidad and Tobago (+1868)' },
    { code: '216', label: 'Tunisia (+216)' },
    { code: '90', label: 'Turkey (+90)' },
    { code: '993', label: 'Turkmenistan (+993)' },
    { code: '1649', label: 'Turks and Caicos Islands (+1649)' },
    { code: '688', label: 'Tuvalu (+688)' },
    { code: '256', label: 'Uganda (+256)' },
    { code: '380', label: 'Ukraine (+380)' },
    { code: '971', label: 'United Arab Emirates (+971)' },
    { code: '44', label: 'United Kingdom (+44)' },
    { code: '1', label: 'United States (+1)' },
    { code: '598', label: 'Uruguay (+598)' },
    { code: '998', label: 'Uzbekistan (+998)' },
    { code: '678', label: 'Vanuatu (+678)' },
    { code: '379', label: 'Vatican City (+379)' },
    { code: '58', label: 'Venezuela (+58)' },
    { code: '84', label: 'Vietnam (+84)' },
    { code: '1284', label: 'British Virgin Islands (+1284)' },
    { code: '1340', label: 'U.S. Virgin Islands (+1340)' },
    { code: '212', label: 'Western Sahara (+212)' },
    { code: '967', label: 'Yemen (+967)' },
    { code: '260', label: 'Zambia (+260)' },
    { code: '263', label: 'Zimbabwe (+263)' }
]

export const countries = [
    {
        "id": 201,
        "name": "Afghanistan",
        "cod": "AF"
    },
    {
        "id": 91,
        "name": "Aland Islands",
        "cod": "AX"
    },
    {
        "id": 219,
        "name": "Albania",
        "cod": "AL"
    },
    {
        "id": 74,
        "name": "Algeria",
        "cod": "DZ"
    },
    {
        "id": 181,
        "name": "American Samoa",
        "cod": "AS"
    },
    {
        "id": 222,
        "name": "Andorra",
        "cod": "AD"
    },
    {
        "id": 144,
        "name": "Angola",
        "cod": "AO"
    },
    {
        "id": 110,
        "name": "Anguilla",
        "cod": "AI"
    },
    {
        "id": 250,
        "name": "Antarctica",
        "cod": "AQ"
    },
    {
        "id": 213,
        "name": "Antigua And Barbuda",
        "cod": "AG"
    },
    {
        "id": 45,
        "name": "Argentina",
        "cod": "AR"
    },
    {
        "id": 79,
        "name": "Armenia",
        "cod": "AM"
    },
    {
        "id": 80,
        "name": "Aruba",
        "cod": "AW"
    },
    {
        "id": 81,
        "name": "Australia",
        "cod": "AU"
    },
    {
        "id": 4,
        "name": "Austria",
        "cod": "AT"
    },
    {
        "id": 82,
        "name": "Azerbaijan",
        "cod": "AZ"
    },
    {
        "id": 83,
        "name": "Bahamas The",
        "cod": "BS"
    },
    {
        "id": 84,
        "name": "Bahrain",
        "cod": "BH"
    },
    {
        "id": 85,
        "name": "Bangladesh",
        "cod": "BD"
    },
    {
        "id": 86,
        "name": "Barbados",
        "cod": "BB"
    },
    {
        "id": 6,
        "name": "Belarus",
        "cod": "BY"
    },
    {
        "id": 5,
        "name": "Belgium",
        "cod": "BE"
    },
    {
        "id": 87,
        "name": "Belize",
        "cod": "BZ"
    },
    {
        "id": 88,
        "name": "Benin",
        "cod": "BJ"
    },
    {
        "id": 89,
        "name": "Bermuda",
        "cod": "BM"
    },
    {
        "id": 95,
        "name": "Bhutan",
        "cod": "BT"
    },
    {
        "id": 46,
        "name": "Bolivia",
        "cod": "BO"
    },
    {
        "id": 7,
        "name": "Bosnia and Herzegovina",
        "cod": "BA"
    },
    {
        "id": 90,
        "name": "Botswana",
        "cod": "BW"
    },
    {
        "id": 231,
        "name": "Bouvet Island",
        "cod": "BV"
    },
    {
        "id": 47,
        "name": "Brasil",
        "cod": "BR"
    },
    {
        "id": 240,
        "name": "British Indian Ocean Territory",
        "cod": "IO"
    },
    {
        "id": 92,
        "name": "Brunei",
        "cod": "BN"
    },
    {
        "id": 8,
        "name": "Bulgaria",
        "cod": "BG"
    },
    {
        "id": 93,
        "name": "Burkina Faso",
        "cod": "BF"
    },
    {
        "id": 94,
        "name": "Burundi",
        "cod": "BI"
    },
    {
        "id": 97,
        "name": "Cambodia",
        "cod": "KH"
    },
    {
        "id": 98,
        "name": "Cameroon",
        "cod": "CM"
    },
    {
        "id": 99,
        "name": "Canada",
        "cod": "CA"
    },
    {
        "id": 96,
        "name": "Cape Verde",
        "cod": "CV"
    },
    {
        "id": 133,
        "name": "Cayman Islands",
        "cod": "KY"
    },
    {
        "id": 175,
        "name": "Central African Republic",
        "cod": "CF"
    },
    {
        "id": 101,
        "name": "Chad",
        "cod": "TD"
    },
    {
        "id": 48,
        "name": "Chile",
        "cod": "CL"
    },
    {
        "id": 102,
        "name": "China",
        "cod": "CN"
    },
    {
        "id": 225,
        "name": "Christmas Island",
        "cod": "CX"
    },
    {
        "id": 232,
        "name": "Cocos (Keeling) Islands",
        "cod": "CC"
    },
    {
        "id": 49,
        "name": "Colombia",
        "cod": "CO"
    },
    {
        "id": 104,
        "name": "Comoros",
        "cod": "KM"
    },
    {
        "id": 176,
        "name": "Congo",
        "cod": "CG"
    },
    {
        "id": 177,
        "name": "Congo The Democratic Republic Of The",
        "cod": "CD"
    },
    {
        "id": 243,
        "name": "Cook Islands",
        "cod": "CK"
    },
    {
        "id": 50,
        "name": "Costa Rica",
        "cod": "CR"
    },
    {
        "id": 73,
        "name": "Cote D Ivoire (Ivory Coast)",
        "cod": "CI"
    },
    {
        "id": 10,
        "name": "Croatia (Hrvatska)",
        "cod": "HR"
    },
    {
        "id": 51,
        "name": "Cuba",
        "cod": "CU"
    },
    {
        "id": 108,
        "name": "Curazao",
        "cod": "CW"
    },
    {
        "id": 103,
        "name": "Cyprus",
        "cod": "CY"
    },
    {
        "id": 36,
        "name": "Czech Republic",
        "cod": "CZ"
    },
    {
        "id": 11,
        "name": "Denmark",
        "cod": "DK"
    },
    {
        "id": 217,
        "name": "Djibouti",
        "cod": "DJ"
    },
    {
        "id": 109,
        "name": "Dominica",
        "cod": "DM"
    },
    {
        "id": 67,
        "name": "Dominican Republic",
        "cod": "DO"
    },
    {
        "id": 52,
        "name": "Ecuador",
        "cod": "EC"
    },
    {
        "id": 111,
        "name": "Egypt",
        "cod": "EG"
    },
    {
        "id": 53,
        "name": "El Salvador",
        "cod": "SV"
    },
    {
        "id": 124,
        "name": "Equatorial Guinea",
        "cod": "GQ"
    },
    {
        "id": 113,
        "name": "Eritrea",
        "cod": "ER"
    },
    {
        "id": 15,
        "name": "Estonia",
        "cod": "EE"
    },
    {
        "id": 115,
        "name": "Ethiopia",
        "cod": "ET"
    },
    {
        "id": 247,
        "name": "External Territories of Australia",
        "cod": "XA"
    },
    {
        "id": 224,
        "name": "Falkland Islands",
        "cod": "FK"
    },
    {
        "id": 78,
        "name": "Faroe Islands",
        "cod": "FO"
    },
    {
        "id": 116,
        "name": "Fiji Islands",
        "cod": "FJ"
    },
    {
        "id": 16,
        "name": "Finland",
        "cod": "FI"
    },
    {
        "id": 17,
        "name": "France",
        "cod": "FR"
    },
    {
        "id": 54,
        "name": "French Guiana",
        "cod": "GF"
    },
    {
        "id": 3,
        "name": "French Polynesia",
        "cod": "PF"
    },
    {
        "id": 234,
        "name": "French Southern Territories",
        "cod": "TF"
    },
    {
        "id": 118,
        "name": "Gabon",
        "cod": "GA"
    },
    {
        "id": 119,
        "name": "Gambia The",
        "cod": "GM"
    },
    {
        "id": 120,
        "name": "Georgia",
        "cod": "GE"
    },
    {
        "id": 223,
        "name": "Germany",
        "cod": "DE"
    },
    {
        "id": 121,
        "name": "Ghana",
        "cod": "GH"
    },
    {
        "id": 114,
        "name": "Gibraltar",
        "cod": "GI"
    },
    {
        "id": 18,
        "name": "Greece",
        "cod": "GR"
    },
    {
        "id": 235,
        "name": "Greenland",
        "cod": "GL"
    },
    {
        "id": 55,
        "name": "Grenada",
        "cod": "GD"
    },
    {
        "id": 221,
        "name": "Guadeloupe",
        "cod": "GP"
    },
    {
        "id": 122,
        "name": "Guam",
        "cod": "GU"
    },
    {
        "id": 56,
        "name": "Guatemala",
        "cod": "GT"
    },
    {
        "id": 241,
        "name": "Guernsey and Alderney",
        "cod": "XU"
    },
    {
        "id": 125,
        "name": "Guinea",
        "cod": "GN"
    },
    {
        "id": 123,
        "name": "Guinea-Bissau",
        "cod": "GW"
    },
    {
        "id": 126,
        "name": "Guyana",
        "cod": "GY"
    },
    {
        "id": 58,
        "name": "Haiti",
        "cod": "HT"
    },
    {
        "id": 242,
        "name": "Heard and McDonald Islands",
        "cod": "HM"
    },
    {
        "id": 59,
        "name": "Honduras",
        "cod": "HN"
    },
    {
        "id": 127,
        "name": "Hong Kong S.A.R.",
        "cod": "HK"
    },
    {
        "id": 19,
        "name": "Hungary",
        "cod": "HU"
    },
    {
        "id": 21,
        "name": "Iceland",
        "cod": "IS"
    },
    {
        "id": 129,
        "name": "India",
        "cod": "IN"
    },
    {
        "id": 130,
        "name": "Indonesia",
        "cod": "ID"
    },
    {
        "id": 132,
        "name": "Iran",
        "cod": "IR"
    },
    {
        "id": 131,
        "name": "Iraq",
        "cod": "IQ"
    },
    {
        "id": 20,
        "name": "Ireland",
        "cod": "IE"
    },
    {
        "id": 137,
        "name": "Israel",
        "cod": "IL"
    },
    {
        "id": 22,
        "name": "Italia",
        "cod": "IT"
    },
    {
        "id": 60,
        "name": "Jamaica",
        "cod": "JM"
    },
    {
        "id": 138,
        "name": "Japan",
        "cod": "JP"
    },
    {
        "id": 227,
        "name": "Jersey",
        "cod": "XJ"
    },
    {
        "id": 139,
        "name": "Jordan",
        "cod": "JO"
    },
    {
        "id": 140,
        "name": "Kazakhstan",
        "cod": "KZ"
    },
    {
        "id": 141,
        "name": "Kenya",
        "cod": "KE"
    },
    {
        "id": 143,
        "name": "Kiribati",
        "cod": "KI"
    },
    {
        "id": 106,
        "name": "Korea North",
        "cod": "KP"
    },
    {
        "id": 145,
        "name": "Kuwait",
        "cod": "KW"
    },
    {
        "id": 142,
        "name": "Kyrgyzstan",
        "cod": "KG"
    },
    {
        "id": 146,
        "name": "Laos",
        "cod": "LA"
    },
    {
        "id": 23,
        "name": "Latvia",
        "cod": "LV"
    },
    {
        "id": 148,
        "name": "Lebanon",
        "cod": "LB"
    },
    {
        "id": 147,
        "name": "Lesotho",
        "cod": "LS"
    },
    {
        "id": 149,
        "name": "Liberia",
        "cod": "LR"
    },
    {
        "id": 150,
        "name": "Libya",
        "cod": "LY"
    },
    {
        "id": 24,
        "name": "Liechtenstein",
        "cod": "LI"
    },
    {
        "id": 25,
        "name": "Lithuania",
        "cod": "LT"
    },
    {
        "id": 26,
        "name": "Luxembourg",
        "cod": "LU"
    },
    {
        "id": 245,
        "name": "Macau S.A.R.",
        "cod": "MO"
    },
    {
        "id": 151,
        "name": "Macedonia",
        "cod": "MK"
    },
    {
        "id": 152,
        "name": "Madagascar",
        "cod": "MG"
    },
    {
        "id": 154,
        "name": "Malawi",
        "cod": "MW"
    },
    {
        "id": 153,
        "name": "Malaysia",
        "cod": "MY"
    },
    {
        "id": 155,
        "name": "Maldives",
        "cod": "MV"
    },
    {
        "id": 156,
        "name": "Mali",
        "cod": "ML"
    },
    {
        "id": 27,
        "name": "Malta",
        "cod": "MT"
    },
    {
        "id": 105,
        "name": "Man (Isle of)",
        "cod": "XM"
    },
    {
        "id": 134,
        "name": "Marshall Islands",
        "cod": "MH"
    },
    {
        "id": 1,
        "name": "Martinique",
        "cod": "MQ"
    },
    {
        "id": 159,
        "name": "Mauritania",
        "cod": "MR"
    },
    {
        "id": 158,
        "name": "Mauritius",
        "cod": "MU"
    },
    {
        "id": 249,
        "name": "Mayotte",
        "cod": "YT"
    },
    {
        "id": 61,
        "name": "Mexico",
        "cod": "MX"
    },
    {
        "id": 160,
        "name": "Micronesia",
        "cod": "FM"
    },
    {
        "id": 28,
        "name": "Moldova",
        "cod": "MD"
    },
    {
        "id": 29,
        "name": "Monaco",
        "cod": "MC"
    },
    {
        "id": 161,
        "name": "Mongolia",
        "cod": "MN"
    },
    {
        "id": 233,
        "name": "Montserrat",
        "cod": "MS"
    },
    {
        "id": 44,
        "name": "Morocco",
        "cod": "MA"
    },
    {
        "id": 162,
        "name": "Mozambique",
        "cod": "MZ"
    },
    {
        "id": 163,
        "name": "Myanmar",
        "cod": "MM"
    },
    {
        "id": 164,
        "name": "Namibia",
        "cod": "NA"
    },
    {
        "id": 165,
        "name": "Nauru",
        "cod": "NR"
    },
    {
        "id": 166,
        "name": "Nepal",
        "cod": "NP"
    },
    {
        "id": 32,
        "name": "Netherlands",
        "cod": "NL"
    },
    {
        "id": 236,
        "name": "Netherlands Antilles",
        "cod": "AN"
    },
    {
        "id": 76,
        "name": "New Caledonia",
        "cod": "NC"
    },
    {
        "id": 168,
        "name": "New Zealand",
        "cod": "NZ"
    },
    {
        "id": 62,
        "name": "Nicaragua",
        "cod": "NI"
    },
    {
        "id": 167,
        "name": "Niger",
        "cod": "NE"
    },
    {
        "id": 72,
        "name": "Nigeria",
        "cod": "NG"
    },
    {
        "id": 237,
        "name": "Niue",
        "cod": "NU"
    },
    {
        "id": 239,
        "name": "Norfolk Island",
        "cod": "NF"
    },
    {
        "id": 157,
        "name": "Northern Mariana Islands",
        "cod": "MP"
    },
    {
        "id": 31,
        "name": "Norway",
        "cod": "NO"
    },
    {
        "id": 169,
        "name": "Oman",
        "cod": "OM"
    },
    {
        "id": 171,
        "name": "Pakistan",
        "cod": "PK"
    },
    {
        "id": 173,
        "name": "Palau",
        "cod": "PW"
    },
    {
        "id": 172,
        "name": "Palestinian Territory Occupied",
        "cod": "PS"
    },
    {
        "id": 64,
        "name": "Panama",
        "cod": "PA"
    },
    {
        "id": 174,
        "name": "Papua new Guinea",
        "cod": "PG"
    },
    {
        "id": 63,
        "name": "Paraguay",
        "cod": "PY"
    },
    {
        "id": 65,
        "name": "Peru",
        "cod": "PE"
    },
    {
        "id": 117,
        "name": "Philippines",
        "cod": "PH"
    },
    {
        "id": 135,
        "name": "Pitcairn Island",
        "cod": "PN"
    },
    {
        "id": 33,
        "name": "Poland",
        "cod": "PL"
    },
    {
        "id": 34,
        "name": "Portugal",
        "cod": "PT"
    },
    {
        "id": 66,
        "name": "Puerto Rico",
        "cod": "PR"
    },
    {
        "id": 100,
        "name": "Qatar",
        "cod": "QA"
    },
    {
        "id": 2,
        "name": "Reunion",
        "cod": "RE"
    },
    {
        "id": 38,
        "name": "Romania",
        "cod": "RO"
    },
    {
        "id": 179,
        "name": "Russia",
        "cod": "RU"
    },
    {
        "id": 178,
        "name": "Rwanda",
        "cod": "RW"
    },
    {
        "id": 77,
        "name": "Saint Barthélémy",
        "cod": "BL"
    },
    {
        "id": 230,
        "name": "Saint Helena",
        "cod": "SH"
    },
    {
        "id": 183,
        "name": "Saint Kitts And Nevis",
        "cod": "KN"
    },
    {
        "id": 184,
        "name": "Saint Lucia",
        "cod": "LC"
    },
    {
        "id": 75,
        "name": "Saint Martin",
        "cod": "MF"
    },
    {
        "id": 229,
        "name": "Saint Pierre and Miquelon",
        "cod": "PM"
    },
    {
        "id": 186,
        "name": "Saint Vincent And The Grenadines",
        "cod": "VC"
    },
    {
        "id": 182,
        "name": "Samoa",
        "cod": "WS"
    },
    {
        "id": 39,
        "name": "San Marino",
        "cod": "SM"
    },
    {
        "id": 185,
        "name": "Sao Tome and Principe",
        "cod": "ST"
    },
    {
        "id": 128,
        "name": "Saudi Arabia",
        "cod": "SA"
    },
    {
        "id": 187,
        "name": "Senegal",
        "cod": "SN"
    },
    {
        "id": 40,
        "name": "Serbia",
        "cod": "RS"
    },
    {
        "id": 188,
        "name": "Seychelles",
        "cod": "SC"
    },
    {
        "id": 189,
        "name": "Sierra Leone",
        "cod": "SL"
    },
    {
        "id": 190,
        "name": "Singapore",
        "cod": "SG"
    },
    {
        "id": 12,
        "name": "Slovakia",
        "cod": "SK"
    },
    {
        "id": 13,
        "name": "Slovenia",
        "cod": "SI"
    },
    {
        "id": 228,
        "name": "Smaller Territories of the UK",
        "cod": "XG"
    },
    {
        "id": 136,
        "name": "Solomon Islands",
        "cod": "SB"
    },
    {
        "id": 192,
        "name": "Somalia",
        "cod": "SO"
    },
    {
        "id": 194,
        "name": "South Africa",
        "cod": "ZA"
    },
    {
        "id": 226,
        "name": "South Georgia",
        "cod": "GS"
    },
    {
        "id": 107,
        "name": "South Korea",
        "cod": "KR"
    },
    {
        "id": 37,
        "name": "South Sudan",
        "cod": "SS"
    },
    {
        "id": 14,
        "name": "Spain",
        "cod": "ES"
    },
    {
        "id": 193,
        "name": "Sri Lanka",
        "cod": "LK"
    },
    {
        "id": 195,
        "name": "Sudan",
        "cod": "SD"
    },
    {
        "id": 68,
        "name": "Suriname",
        "cod": "SR"
    },
    {
        "id": 252,
        "name": "Svalbard And Jan Mayen Islands",
        "cod": "SJ"
    },
    {
        "id": 196,
        "name": "Swaziland",
        "cod": "SZ"
    },
    {
        "id": 41,
        "name": "Sweden",
        "cod": "SE"
    },
    {
        "id": 42,
        "name": "Switzerland",
        "cod": "CH"
    },
    {
        "id": 191,
        "name": "Syria",
        "cod": "SY"
    },
    {
        "id": 198,
        "name": "Taiwan",
        "cod": "TW"
    },
    {
        "id": 200,
        "name": "Tajikistan",
        "cod": "TJ"
    },
    {
        "id": 199,
        "name": "Tanzania",
        "cod": "TZ"
    },
    {
        "id": 197,
        "name": "Thailand",
        "cod": "TH"
    },
    {
        "id": 202,
        "name": "Timor Leste",
        "cod": "TL"
    },
    {
        "id": 203,
        "name": "Togo",
        "cod": "TG"
    },
    {
        "id": 170,
        "name": "Tokelau",
        "cod": "TK"
    },
    {
        "id": 204,
        "name": "Tonga",
        "cod": "TO"
    },
    {
        "id": 205,
        "name": "Trinidad And Tobago",
        "cod": "TT"
    },
    {
        "id": 206,
        "name": "Tunisia",
        "cod": "TN"
    },
    {
        "id": 208,
        "name": "Turkey",
        "cod": "TR"
    },
    {
        "id": 207,
        "name": "Turkmenistan",
        "cod": "TM"
    },
    {
        "id": 238,
        "name": "Turks And Caicos Islands",
        "cod": "TC"
    },
    {
        "id": 209,
        "name": "Tuvalu",
        "cod": "TV"
    },
    {
        "id": 210,
        "name": "Uganda",
        "cod": "UG"
    },
    {
        "id": 43,
        "name": "Ukraine",
        "cod": "UA"
    },
    {
        "id": 112,
        "name": "United Arab Emirates",
        "cod": "AE"
    },
    {
        "id": 35,
        "name": "United Kingdom",
        "cod": "GB"
    },
    {
        "id": 246,
        "name": "United States Minor Outlying Islands",
        "cod": "UM"
    },
    {
        "id": 71,
        "name": "United States of America",
        "cod": "US"
    },
    {
        "id": 69,
        "name": "Uruguay",
        "cod": "UY"
    },
    {
        "id": 211,
        "name": "Uzbekistan",
        "cod": "UZ"
    },
    {
        "id": 212,
        "name": "Vanuatu",
        "cod": "VU"
    },
    {
        "id": 9,
        "name": "Vatican City State (Holy See)",
        "cod": "VA"
    },
    {
        "id": 70,
        "name": "Venezuela",
        "cod": "VE"
    },
    {
        "id": 214,
        "name": "Vietnam",
        "cod": "VN"
    },
    {
        "id": 244,
        "name": "Virgin Islands (British)",
        "cod": "VG"
    },
    {
        "id": 57,
        "name": "Virgin Islands (US)",
        "cod": "VI"
    },
    {
        "id": 215,
        "name": "Wallis And Futuna Islands",
        "cod": "WF"
    },
    {
        "id": 180,
        "name": "Western Sahara",
        "cod": "EH"
    },
    {
        "id": 251,
        "name": "Western Sahara",
        "cod": "EH"
    },
    {
        "id": 216,
        "name": "Yemen",
        "cod": "YE"
    },
    {
        "id": 248,
        "name": "Yugoslavia",
        "cod": "YU"
    },
    {
        "id": 218,
        "name": "Zambia",
        "cod": "ZM"
    },
    {
        "id": 220,
        "name": "Zimbabwe",
        "cod": "ZW"
    }
]
