//Generated by Java Application on Thu Dec 12 10:06:48 EST 2024

import localeAntd from 'antd/es/locale/fr_FR'

const messages = {
'casino.cashier.alert1.description': 'Vous devez compléter vos informations personnelles pour pouvoir effectuer un dépôt ou un retrait. Cliquez sur cet avis ou visitez la section Modifier le Profil.',
'casino.cashier.alert1.message': 'Important!',
'casino.cashier.alert2.description': "Vous devez faire vérifier votre numéro de téléphone et ajouter une pièce d'identité afin de pouvoir effectuer des retraits. Cliquez sur cet avis ou visitez la section Modifier le Profil.",
'casino.cashier.alert3.description': 'Vos documents (photos) ont été rejetés. SVP télécharger de nouvelles photos dans votre profil. Cliquer sur ce message ou visiter la section "Mon Profil".',
'casino.cashier.currentBalance': 'Solde Actuel',
'casino.general.date.from': 'Du',
'casino.general.date.to': 'au',
'casino.iframegame.title': 'CASINO',
'casino.modal.popup.close': 'Fermer',
'casino.rg.activitycheck': "Surveillance d'activité",
'casino.rg.activitycheckmsg': 'Option permettant de définir un rappel pour les notifications décrivant le temps passé à jouer.',
'casino.rg.depositlimit': 'Limite de dépot',
'casino.rg.depositlimitmsg': 'Fixez une limite au montant que vous pouvez déposer sur une période donnée.',
'casino.rg.losslimit': 'Limite de perte',
'casino.rg.losslimitmsg': "Fixez une limite au montant maximum d'argent que vous pouvez perdre sur une période donnée.",
'casino.rg.selfexclusion': 'Auto-exclusion',
'casino.rg.selfexclusionmsg': "Prenez un congé indéfini de notre part en demandant l'auto-exclusion. (Minimum 6 mois)",
'casino.rg.setlimit': 'Définir une limite',
'casino.rg.takebreak': 'Prendre une pause',
'casino.rg.takebreakmsg': "Si vous souhaitez faire une courte pause dans le jeu, vous pouvez le faire en prenant une pause d'une durée de 1 jour, 7 jours ou 30 jours.",
'casino.rg.title': 'Jeu responsable',
'newCasino.admin.partnerDeal.title': 'Contrat de partenaire',
'newCasino.admin.streamerDeal.amount': 'Montant',
'newCasino.admin.streamerDeal.cancel': 'Annuler',
'newCasino.admin.streamerDeal.giveaway': 'Montant pour don',
'newCasino.admin.streamerDeal.perweek': 'Quantité par semaine',
'newCasino.admin.streamerDeal.save': 'Sauvegarder',
'newCasino.admin.streamerDeal.subtitle': 'Ajuster les paramètres du contrat',
'newCasino.admin.streamerDeal.title': "Contrat d'influenceur",
'newCasino.admin.streamerDeal.xtime': 'Multiplicateur',
'newcasino.admin.freespin.add.success': '{AMOUNT} tours gratuits ont été ajoutés au portefeuille du joueur.',
'newcasino.admin.freespin.remove.success': '{AMOUNT} tours gratuits ont été retirés du portefeuille du joueur.',
'newcasino.admin.kyc.approved': 'La demande a été approuvée',
'newcasino.admin.kyc.reject': 'La demande a été rejetée',
'newcasino.admin.money.add.success': 'Le montant de {AMOUNT} a été ajouté au compte du joueur.',
'newcasino.admin.money.remove.success': 'Le montant de {AMOUNT} a été retiré du compte du joueur.',
'newcasino.admin.pngpoints.add.success': '{AMOUNT} points ont été ajoutés au joueur.',
'newcasino.admin.pngpoints.remove.success': '{AMOUNT} points ont été retirés au joueur.',
'newcasino.admin.prom.depositbonus.tx.col1': 'Courriel',
'newcasino.admin.prom.depositbonus.tx.col2': 'Devise',
'newcasino.admin.prom.depositbonus.tx.col3': 'Prix',
'newcasino.admin.prom.depositbonus.winners': 'Gagnants',
'newcasino.admin.prom.wager.tx.col1': 'Courriel',
'newcasino.admin.prom.wager.tx.col2': 'Devise',
'newcasino.admin.prom.wager.tx.col3': 'Solde',
'newcasino.admin.prom.wager.tx.col4': 'Cible',
'newcasino.admin.prom.wager.tx.col5': 'Prix',
'newcasino.admin.spinInfo.col1': 'Nom du jeu',
'newcasino.admin.spinInfo.col2': 'Nbr Tour',
'newcasino.admin.user.address.address.error': 'Trop court',
'newcasino.admin.user.address.address.hint': 'Adresse',
'newcasino.admin.user.address.address.label': 'Adresse',
'newcasino.admin.user.address.city.error': 'Trop court',
'newcasino.admin.user.address.city.hint': 'Ville',
'newcasino.admin.user.address.city.label': 'Ville',
'newcasino.admin.user.address.country.hint': 'Sélectionner Pays...',
'newcasino.admin.user.address.country.label': 'Pays',
'newcasino.admin.user.address.currency.label': 'Devise',
'newcasino.admin.user.address.dob.label': 'Date de naissance',
'newcasino.admin.user.address.email.label': 'Courriel',
'newcasino.admin.user.address.id.label': 'Id',
'newcasino.admin.user.address.phone.label': 'No de téléphone',
'newcasino.admin.user.address.regdate.label': 'Membre depuis',
'newcasino.admin.user.address.sponsorId.label': 'Id Parrain',
'newcasino.admin.user.address.state.error': 'Trop court',
'newcasino.admin.user.address.state.hint': 'État/Province',
'newcasino.admin.user.address.state.label': 'État/Province',
'newcasino.admin.user.address.zip.error': 'Trop court',
'newcasino.admin.user.address.zip.hint': 'Code Postal',
'newcasino.admin.user.address.zip.label': 'Code Postal',
'newcasino.admin.user.button': 'Mettre à jour',
'newcasino.admin.user.note.hint': "Ajouter une note au dossier de l'utilisateur",
'newcasino.admin.user.note.label': 'Note',
'newcasino.admin.user.pngpoint.hist.col1': 'Date',
'newcasino.admin.user.pngpoint.hist.col2': 'Type',
'newcasino.admin.user.pngpoint.hist.col3': 'Détail',
'newcasino.admin.user.pngpoint.hist.col4': 'Prix',
'newcasino.admin.user.pngpoints.add.button': '+/- Points',
'newcasino.admin.user.pngpoints.available': 'Points de fidélité disponibles',
'newcasino.admin.user.pngpoints.daily.list.title': 'Cadeaux quotidiens',
'newcasino.admin.user.pngpoints.level': 'Niveau de fidélité',
'newcasino.admin.user.pngpoints.purchase.list.title': 'Détails des achats',
'newcasino.admin.user.pngpoints.subtitle': 'Ajouter/Retirer des points de loyauté',
'newcasino.admin.user.pngpoints.title': 'Points de Loyauté',
'newcasino.admin.user.pngpoints.used': 'Points de fidélité utilisés',
'newcasino.admin.user.popup.cash.amount.hint': 'Montant',
'newcasino.admin.user.popup.cash.amount.label': 'Montant',
'newcasino.admin.user.popup.cash.button.add': 'Ajouter/Retirer',
'newcasino.admin.user.popup.cash.button.cancel': 'Annuler',
'newcasino.admin.user.popup.cash.subtitle': "Ajouter/Retirer de l'argent véritable au joueur",
'newcasino.admin.user.popup.cash.title': 'Vrai Argent',
'newcasino.admin.user.popup.freespin.button.add': 'Ajouter/Retirer',
'newcasino.admin.user.popup.freespin.button.cancel': 'Annuler',
'newcasino.admin.user.popup.freespin.info.subtitle': 'Informations sur les tours gratuits des fournisseurs de jeux',
'newcasino.admin.user.popup.freespin.quantity.hint': 'Quantité',
'newcasino.admin.user.popup.freespin.quantity.label': 'Quantité',
'newcasino.admin.user.popup.freespin.subtitle': 'Ajouter/Retirer des tours gratuits au portefeuille',
'newcasino.admin.user.popup.freespin.title': 'Tours gratuits',
'newcasino.admin.user.popup.switchphone.button.add': 'Procéder',
'newcasino.admin.user.popup.switchphone.button.cancel': 'Annuler',
'newcasino.admin.user.popup.switchphone.combo.label': 'Action',
'newcasino.admin.user.popup.switchphone.subtitle': "Sélectionner l'action à effectuer",
'newcasino.admin.user.popup.switchphone.success': "L'action a été effectuée",
'newcasino.admin.user.popup.switchphone.title': 'Changer le status téléphonique',
'newcasino.admin.user.popup.switchstatus.button.add': 'Modifier',
'newcasino.admin.user.popup.switchstatus.button.cancel': 'Annuler',
'newcasino.admin.user.popup.switchstatus.combo.label': 'Status',
'newcasino.admin.user.popup.switchstatus.subtitle': 'Sélectionner le nouveau status',
'newcasino.admin.user.popup.switchstatus.success': "Le status de l'utilisateur a été modifié",
'newcasino.admin.user.popup.switchstatus.title': "Changer le status de d'utilisateur",
'newcasino.admin.user.popup.switchuser.button.add': 'Modifier',
'newcasino.admin.user.popup.switchuser.button.cancel': 'Annuler',
'newcasino.admin.user.popup.switchuser.combo.label': 'Type',
'newcasino.admin.user.popup.switchuser.subtitle': "Sélectionner le nouveau type d'utilisateur",
'newcasino.admin.user.popup.switchuser.success': "Le type d'utilisateur a été modifié",
'newcasino.admin.user.popup.switchuser.title': "Changer le type d'utilisateur",
'newcasino.admin.user.popup.tx.cancel': 'Fermer',
'newcasino.admin.user.popup.tx.title': 'Détail de la transaction',
'newcasino.admin.user.popup.wager.amount.hint': 'Montant',
'newcasino.admin.user.popup.wager.amount.label': 'Montant',
'newcasino.admin.user.popup.wager.button.add': 'Ajouter',
'newcasino.admin.user.popup.wager.button.cancel': 'Annuler',
'newcasino.admin.user.popup.wager.expiration': "Date d'expiration",
'newcasino.admin.user.popup.wager.list.hint': 'Un Courriel/UserId par line',
'newcasino.admin.user.popup.wager.list.label': 'Liste de Courriel/UserId',
'newcasino.admin.user.popup.wager.max.hint': 'Gain Maximum',
'newcasino.admin.user.popup.wager.max.label': 'Gain Maximum',
'newcasino.admin.user.popup.wager.streamer': "C'est un bonus pour influenceur",
'newcasino.admin.user.popup.wager.subtitle': "Veuillez fournir l'information",
'newcasino.admin.user.popup.wager.title': 'Ajouter un bonus',
'newcasino.admin.user.popup.wager.x.hint': 'X Fois',
'newcasino.admin.user.popup.wager.x.label': 'Nombre de rotation',
'newcasino.admin.user.update.success': 'Les informations ont été mises à jour.',
'newcasino.admin.user.wallet.add.freespin': '+/- Tours gratuits',
'newcasino.admin.user.wallet.add.freespin.info': 'Info Tours gratuits',
'newcasino.admin.user.wallet.add.money': '+/- Vrai Argent',
'newcasino.admin.user.wallet.add.wager': '+ Wager',
'newcasino.admin.user.wallet.col1': 'Type',
'newcasino.admin.user.wallet.col2': 'État',
'newcasino.admin.user.wallet.col3': 'Solde',
'newcasino.admin.user.wallet.col4': 'Cible Bonus',
'newcasino.admin.user.wallet.col5': 'Bonus gagné',
'newcasino.admin.user.wallet.col6': 'Tours Grat.',
'newcasino.admin.user.wallet.freespin.info.nodata': 'Aucun tour gratuit en attente chez le fournisseur',
'newcasino.admin.user.wallet.state.3xpending': 'Bonus 3x en attente',
'newcasino.admin.user.wallet.state.active': 'Actif',
'newcasino.admin.user.wallet.state.inactive': 'Inactif',
'newcasino.admin.user.wallet.state.unknow': 'Inconnu',
'newcasino.admin.user.wallet.state.unplayable': 'Non jouable',
'newcasino.admin.user.wallet.tx.col1': 'Date',
'newcasino.admin.user.wallet.tx.col2': 'Type',
'newcasino.admin.user.wallet.tx.col3': 'Montant',
'newcasino.admin.user.wallet.tx.col4': 'Solde',
'newcasino.admin.user.wallet.tx.title': 'Transactions au portefeuille',
'newcasino.admin.user.wallet.type.realmoney': 'Argent réel',
'newcasino.admin.user.wallet.type.streamer.wager': 'Bonus Influenceur',
'newcasino.admin.user.wallet.type.unknow': 'Inconnu',
'newcasino.admin.user.wallet.type.wager': 'Bonus',
'newcasino.admin.user.wallet.wallets.title': 'Portefeuilles du joueur',
'newcasino.admin.userlist.col1': 'Nom',
'newcasino.admin.userlist.col2': 'Courriel',
'newcasino.admin.userlist.col3': 'Options',
'newcasino.admin.userlist.download': 'Téléchargement en cours',
'newcasino.admin.userlist.export': 'Exporter vers CSV',
'newcasino.admin.userlist.list.filters': 'Filtres',
'newcasino.admin.userlist.list.filters.banned': 'En pause',
'newcasino.admin.userlist.list.filters.kyc': 'KYC En Approbation',
'newcasino.admin.userlist.list.filters.streamers': 'Influenceurs',
'newcasino.admin.userlist.list.noresult': 'Aucun Résultat',
'newcasino.admin.userlist.list.title': 'Liste des joueurs',
'newcasino.admin.userlist.search': 'Recherche',
'newcasino.admin.userlist.title': 'Gestion des utilisateurs',
'newcasino.admin.wager.add.success': 'Un nouveau portefeuille (Wager) a été créé avec un montant de {AMOUNT} - {X}x',
'newcasino.aff.affiliates.approve.fail': 'La demande a échouée.',
'newcasino.aff.affiliates.approve.pass': 'La demande a été approuvée',
'newcasino.aff.affiliates.get.list.fail': 'Impossible de charger la liste.',
'newcasino.aff.affiliates.pending.col.action': 'Action',
'newcasino.aff.affiliates.pending.col.approvalDate': 'Membre depuis',
'newcasino.aff.affiliates.pending.col.avg': 'Moyenne de vues',
'newcasino.aff.affiliates.pending.col.email': 'Courriel',
'newcasino.aff.affiliates.pending.col.name': 'Nom',
'newcasino.aff.affiliates.pending.col.twitch': 'Twitch',
'newcasino.aff.affiliates.reject.fail': 'La demande a échouée.',
'newcasino.aff.affiliates.reject.pass': 'La demande a été rejetée',
'newcasino.aff.auth.footer.contact': 'Nous rejoindre',
'newcasino.aff.auth.footer.copy': '© 2024 Numbers Game. Tous droits réservés.',
'newcasino.aff.auth.footer.policy': 'Politique de confidentialité',
'newcasino.aff.auth.footer.terms': 'Termes et conditions',
'newcasino.aff.auth.forget.button.back': 'Retour',
'newcasino.aff.auth.forget.button.reset': 'Réinitialiser',
'newcasino.aff.auth.forget.fail': 'Impossible de vous envoyer un courriel',
'newcasino.aff.auth.forget.sub.title': 'Aucun Problème, tapez votre adresse de courriel, et nous vous enverrons un lien pour le changer.',
'newcasino.aff.auth.forget.success': "C'est fait!, allez voir vos courriels.",
'newcasino.aff.auth.forget.title': 'Vous avez oublié votre mot de passe?',
'newcasino.aff.auth.login.button.login': 'Entrer',
'newcasino.aff.auth.login.button.register': 'Enregistrement',
'newcasino.aff.auth.login.email': 'Adresse courriel',
'newcasino.aff.auth.login.email.err': 'Adresse courriel invalide',
'newcasino.aff.auth.login.email.hint': 'Entrer votre courriel ici',
'newcasino.aff.auth.login.err.fail': "Mot de passe ou nom d'usager invalide.",
'newcasino.aff.auth.login.forget': 'Mot de passe oublié?',
'newcasino.aff.auth.login.password': 'Mot de passe',
'newcasino.aff.auth.login.stay': 'Rester connecter',
'newcasino.aff.auth.login.sub.title': "Veuillez entrer vos informations d'authentification ou alors enregistrez-vous.",
'newcasino.aff.auth.login.title': "Bienvenue dans l'arrière boutique Numbers Game",
'newcasino.aff.auth.reg.cie': 'Nom de la compagnie (optionnel)',
'newcasino.aff.auth.reg.cie.err': 'Invalide',
'newcasino.aff.auth.reg.country': 'Pays',
'newcasino.aff.auth.reg.country.hint': 'Votre pays...',
'newcasino.aff.auth.reg.currency': 'Monnaie',
'newcasino.aff.auth.reg.currency.hint': 'Votre monnaie...',
'newcasino.aff.auth.reg.dob': 'Date de naissance',
'newcasino.aff.auth.reg.dob.err': 'Invalide - 18+',
'newcasino.aff.auth.reg.dob.hint': 'Ex.: dd/mm/yyyy',
'newcasino.aff.auth.reg.email': 'Adresse courriel',
'newcasino.aff.auth.reg.email.err': 'Adresse invalide',
'newcasino.aff.auth.reg.err.fail': 'Impossible de vous inscrire avec ce courriel',
'newcasino.aff.auth.reg.fullname': 'Nom complet',
'newcasino.aff.auth.reg.fullname.err': 'Invalide',
'newcasino.aff.auth.reg.password': 'Mot de passe',
'newcasino.aff.auth.reg.password.err': 'Le mot de passe doit avoir au moins 8 caractères avec majuscule et minuscule',
'newcasino.aff.auth.reg.password2': 'Confirmation mot de passe',
'newcasino.aff.auth.reg.password2.err': 'Ne sont pas pareil',
'newcasino.aff.auth.reg.streaming': 'Page de Streaming (optionnel)',
'newcasino.aff.auth.reg.streaming.err': 'Invalide',
'newcasino.aff.auth.reg.sub.channel': 'Votre chaîne',
'newcasino.aff.auth.reg.sub.connection': 'Informations de connexion',
'newcasino.aff.auth.reg.sub.personnal': 'Informations personnelles',
'newcasino.aff.auth.reg.sub.title': 'Entrer les informations requises pour vous enregistrer.',
'newcasino.aff.auth.reg.title': 'Enregistrement',
'newcasino.aff.auth.reg.views': 'Moyenne de vues (optionnel)',
'newcasino.aff.auth.reg.views.err': 'Invalide',
'newcasino.aff.auth.reg.website': 'Site Web (optionel)',
'newcasino.aff.auth.reg.website.err': 'Invalide',
'newcasino.aff.commissions.calendar.1': 'Janvier',
'newcasino.aff.commissions.calendar.10': 'Octobre',
'newcasino.aff.commissions.calendar.11': 'Novembre',
'newcasino.aff.commissions.calendar.12': 'Décembre',
'newcasino.aff.commissions.calendar.2': 'Février',
'newcasino.aff.commissions.calendar.3': 'Mars',
'newcasino.aff.commissions.calendar.4': 'Avril',
'newcasino.aff.commissions.calendar.5': 'Mai',
'newcasino.aff.commissions.calendar.6': 'Juin',
'newcasino.aff.commissions.calendar.7': 'Juillet',
'newcasino.aff.commissions.calendar.8': 'Août',
'newcasino.aff.commissions.calendar.9': 'Septembre',
'newcasino.aff.commissions.detail.amount': 'Montant',
'newcasino.aff.commissions.detail.comm': 'Commission',
'newcasino.aff.commissions.detail.fixed': 'Montant Fixe',
'newcasino.aff.commissions.detail.ftd': 'FTD',
'newcasino.aff.commissions.detail.ftd.no': 'NON',
'newcasino.aff.commissions.detail.ftd.yes': 'OUI',
'newcasino.aff.commissions.detail.no': 'Aucune règle de commission pour cet utilisateur',
'newcasino.aff.commissions.detail.perc': 'Pourcentage',
'newcasino.aff.commissions.detail.percdeposit': '% Dépots',
'newcasino.aff.commissions.detail.percvault': '% Voûte',
'newcasino.aff.commissions.detail.subtotal': 'Sous-Total',
'newcasino.aff.commissions.detail.total': 'Total',
'newcasino.aff.commissions.detail.type': 'Type de commission',
'newcasino.aff.commissions.grid.currency': 'Devise de la Grille:',
'newcasino.aff.commissions.grid.maxWin': 'Commission Max.:',
'newcasino.aff.commissions.grid.title': 'Pourcentage de commission sur la voûte basé sur les dépôts',
'newcasino.aff.commissions.grid.unlimited': 'Illimité',
'newcasino.aff.commissions.load.fail': 'Impossible de charger les commissions, contactez-nous!',
'newcasino.aff.commissions.nopay.button': 'Poubelle',
'newcasino.aff.commissions.notpaid.button': 'Impayée',
'newcasino.aff.commissions.paid.button': 'Payée',
'newcasino.aff.commissions.paid.total': 'Commission payée:',
'newcasino.aff.commissions.pay.button': 'Payer',
'newcasino.aff.commissions.pay.convRate': 'Taux conversion',
'newcasino.aff.commissions.pay.fixed': 'Montant mensuel fixe',
'newcasino.aff.commissions.pay.minftd': 'Min. FTD',
'newcasino.aff.commissions.pay.paymCurrency': 'Monnaie utilisée pour calculs:',
'newcasino.aff.commissions.pay.paymDate': 'Date de paiement:',
'newcasino.aff.commissions.pay.percDeposit': '% Dépot',
'newcasino.aff.commissions.pay.percVault': '% Voute',
'newcasino.aff.commissions.pay.success': "L'influenceur a été payé.",
'newcasino.aff.commissions.pay.total': 'Commission à payer:',
'newcasino.aff.commissions.pay.usegrid': 'Grille',
'newcasino.aff.commissions.refused.button': 'Refusée',
'newcasino.aff.commissions.title': 'Commissions',
'newcasino.aff.commissions.trash.success': "L'influenceur n'a été payé.",
'newcasino.aff.contactus.add1': 'First Floor, Millennium House,',
'newcasino.aff.contactus.add2': 'Victoria Road, Douglas, IM24RW,?',
'newcasino.aff.contactus.add3': 'Isle of Man',
'newcasino.aff.contactus.button.send': 'Envoyer',
'newcasino.aff.contactus.email': 'Quel est votre courriel?',
'newcasino.aff.contactus.msg': 'Tapez votre message ici',
'newcasino.aff.contactus.name': 'Quel est votre nom?',
'newcasino.aff.contactus.sub1': 'Une question? Contactez-nous',
'newcasino.aff.contactus.sub2': 'Où nous trouver?',
'newcasino.aff.contactus.subject': 'À quel sujet?',
'newcasino.aff.contactus.success': 'Merci! Nous avons reçu votre message',
'newcasino.aff.contactus.title': 'Nous rejoindre',
'newcasino.aff.dashboard.active.player': 'Joueurs Actifs',
'newcasino.aff.dashboard.commission.link': 'Voir toutes les commissions',
'newcasino.aff.dashboard.dailygift.open': 'Nombre total de cadeaux quotidiens ouverts',
'newcasino.aff.dashboard.first.time.deposit.player': 'Premiers Dépôts',
'newcasino.aff.dashboard.load.fail': 'Impossible de charger le tableau de bord, contactez-nous!',
'newcasino.aff.dashboard.period.lm': 'Le mois dernier',
'newcasino.aff.dashboard.period.lw': 'Semaine dernière',
'newcasino.aff.dashboard.period.ly': "L'an passé",
'newcasino.aff.dashboard.period.tm': 'Ce mois-ci',
'newcasino.aff.dashboard.period.today': "Aujourd'hui",
'newcasino.aff.dashboard.period.tw': 'Cette semaine',
'newcasino.aff.dashboard.period.ty': 'Cette année',
'newcasino.aff.dashboard.period.yest': 'Hier',
'newcasino.aff.dashboard.refId': 'Lien de parrainage',
'newcasino.aff.dashboard.refId.copied': 'Copié dans le presse-papier',
'newcasino.aff.dashboard.registration.total.email.verified': 'Courriel vérifié',
'newcasino.aff.dashboard.registration.total.player': 'Nouveaux joueurs',
'newcasino.aff.dashboard.registration.total.started': 'Inscriptions - Commencées/Terminées',
'newcasino.aff.dashboard.sponsered.players': 'Nombre total de joueurs sponsorisés',
'newcasino.aff.dashboard.title': 'Tableau de bord',
'newcasino.aff.dashboard.total.10.commission': 'Commissions Payées',
'newcasino.aff.dashboard.total.deposit': 'Total des dépôts',
'newcasino.aff.dashboard.total.deposit.graph.last12Months': 'Dépôts derniers mois',
'newcasino.aff.dashboard.total.deposit.graph.last7Days': 'Dépôts derniers jours',
'newcasino.aff.dashboard.total.deposit.graph.last7SameDay': 'Derniers {DAY}',
'newcasino.aff.dashboard.total.deposit.graph.last7Weeks': 'Dépôts dernières semaines',
'newcasino.aff.menuleft.affiliates': 'Partenaires',
'newcasino.aff.menuleft.commissions': 'Commissions',
'newcasino.aff.menuleft.copyright1': '©2024 Numbers Game',
'newcasino.aff.menuleft.copyright2': 'Tous droits réservés',
'newcasino.aff.menuleft.dashboard': 'Tableau de bord',
'newcasino.aff.policy.button': 'Fermer',
'newcasino.aff.policy.title': 'Politique de confidentialité',
'newcasino.aff.regsuccess.button': 'Continuer',
'newcasino.aff.regsuccess.msg': "Merci de vous être enregistré! Un de nos représentants vous contactera d'ici 48 heures",
'newcasino.aff.regsuccess.title': 'Bienvenue!',
'newcasino.aff.terms.button': 'Fermer',
'newcasino.aff.terms.title': 'Termes et conditions',
'newcasino.aff.userlist.approve': 'Approuver',
'newcasino.aff.userlist.approved': 'Membres',
'newcasino.aff.userlist.emtpy': 'Aucun utilisateur',
'newcasino.aff.userlist.pending': "En attente d'approbation",
'newcasino.aff.userlist.reject': 'Rejeter',
'newcasino.aff.userlist.sub.title': 'Liste des partenaires',
'newcasino.aff.userlist.title': 'Partenaires',
'newcasino.auth.log.error.badcredential': 'Courriel ou mot de passe invalide',
'newcasino.auth.log.error.break': 'Vous avez pris congé du jeu! Vous pourrez revenir à cette date:',
'newcasino.auth.log.error.emailnotverified': "Votre adresse de courriel n'a pas été vérifiée. Vous devez aller cliquer sur le lien de vérification qui vous a été envoyé par courriel. Pour recevoir un nouveau lien de vérification, cliquez sur ce message.",
'newcasino.auth.log.error.unknown': 'Oups... Quelque chose ne va pas bien, contactez le support',
'newcasino.auth.log.msg.passreset.msg': 'Nous venons de vous envoyer un courriel contenant un lien pour réinitialiser votre mot de passe.',
'newcasino.auth.log.msg.passreset.title': 'Regarder votre courrier',
'newcasino.auth.log.msg.verifemail.msg': 'Nous venons de vous envoyer un courriel contenant un lien pour valider votre adresse de courriel.',
'newcasino.auth.log.msg.verifemail.title': 'Regarder votre courrier',
'newcasino.auth.login.avail.freespin': "Vous avez des tours gratuits disponibles, vous pouvez les utiliser sur l'un des jeux sous l'onglet 'Tours gratuits' juste en dessous.",
'newcasino.auth.login.button.cancel': 'Annuler',
'newcasino.auth.login.button.login': 'Entrer',
'newcasino.auth.login.button.noaccount': "Vous n'avez pas de compte?",
'newcasino.auth.login.button.signup': 'Enregistrez-vous maintenant!',
'newcasino.auth.login.email': 'Courriel',
'newcasino.auth.login.email.err': 'Courriel Invalide',
'newcasino.auth.login.forgot_password': 'Mot de passe oublié ?',
'newcasino.auth.login.password': 'Mot de passe',
'newcasino.auth.login.reset.button.send': 'Envoyer le courriel',
'newcasino.auth.login.reset.subTitle': 'Vous avez oublié votre mot de passe? Aucun problème, nous allons vous envoyer un courriel pour le réinitialiser.',
'newcasino.auth.login.reset.title': 'Mot de passe perdu?',
'newcasino.auth.login.stayconnected': 'Rester connecté',
'newcasino.auth.login.subTitle': 'Bienvenue chez Numbers Game! Veuillez fournir vos informations de connexion',
'newcasino.auth.login.title': 'Connexion',
'newcasino.auth.login.type.email': 'Taper votre courriel',
'newcasino.auth.login.type.password': 'Taper votre mot de passe',
'newcasino.auth.registration.address.address.error': 'Votre adresse est trop courte',
'newcasino.auth.registration.address.address.hint': 'Entrer votre adresse',
'newcasino.auth.registration.address.address.label': 'Adresse',
'newcasino.auth.registration.address.already': 'Vous avez déjà un compte?',
'newcasino.auth.registration.address.back': 'Retour',
'newcasino.auth.registration.address.city.error': 'Votre ville est trop courte',
'newcasino.auth.registration.address.city.hint': 'Entrer votre ville',
'newcasino.auth.registration.address.city.label': 'Ville',
'newcasino.auth.registration.address.country.hint': 'Sélectionner votre pays...',
'newcasino.auth.registration.address.country.label': 'Pays',
'newcasino.auth.registration.address.currency.hint': 'Selectionner votre devise...',
'newcasino.auth.registration.address.currency.label': 'Devise',
'newcasino.auth.registration.address.next': 'Continuer 4/5',
'newcasino.auth.registration.address.phone.error': 'Trop court',
'newcasino.auth.registration.address.phone.hint': 'Numéro de téléphone',
'newcasino.auth.registration.address.phone.label': 'Numéro de téléphone',
'newcasino.auth.registration.address.phonecode.hint': 'Code de pays...',
'newcasino.auth.registration.address.phonecode.label': 'Téléphone - Code de pays',
'newcasino.auth.registration.address.state.error': 'Votre province est trop courte',
'newcasino.auth.registration.address.state.hint': 'Province',
'newcasino.auth.registration.address.state.label': 'Province/État',
'newcasino.auth.registration.address.subTitle': 'Entrer les informations requises',
'newcasino.auth.registration.address.title': 'Enregistrement',
'newcasino.auth.registration.address.zip.error': 'Votre code postal est trop court',
'newcasino.auth.registration.address.zip.hint': 'Code Postal',
'newcasino.auth.registration.address.zip.label': 'Code Postal',
'newcasino.auth.registration.bonus.already': 'Vous avez déjà un compte?',
'newcasino.auth.registration.bonus.already.link': 'Se connecter',
'newcasino.auth.registration.bonus.cancel': 'Annuler',
'newcasino.auth.registration.bonus.cashback': "Recevez 3X le montant de votre premier dépôt, jusqu'à 1500$ en Bonus",
'newcasino.auth.registration.bonus.cashback.subtitle': '*Exigence de mise de 35x',
'newcasino.auth.registration.bonus.next': 'Continuer 1/5',
'newcasino.auth.registration.bonus.none': 'Aucune promotion',
'newcasino.auth.registration.bonus.promocode': "J'ai un code promotionnel!",
'newcasino.auth.registration.bonus.refer': 'Référez un ami',
'newcasino.auth.registration.bonus.refer.desc': 'Déposez et jouez un minimum de 30 $ et vous et votre ami recevrez 15 tours gratuits !',
'newcasino.auth.registration.bonus.refer2': 'Procéder à la prochaine étape ou selectionner une promotion.',
'newcasino.auth.registration.bonus.refered': 'Votre ami vous a référé!',
'newcasino.auth.registration.bonus.subTitle': 'Bienvenue chez Numbers Game! Sélectionner une promotion.',
'newcasino.auth.registration.bonus.title': 'Enregistrement',
'newcasino.auth.registration.completed.next': 'Entrer maintenant',
'newcasino.auth.registration.completed.text1': 'Bravo, vous avez',
'newcasino.auth.registration.completed.text2': 'complété votre enregistrement',
'newcasino.auth.registration.completed.text3': '! Vous pouvez maintenant accéder à votre compte et au dépôt.',
'newcasino.auth.registration.completed.text4': 'Veuillez noter!',
'newcasino.auth.registration.completed.text5': "Vous devez compléter la procédure de vérification d'identité afin de pouvoir encaisser votre argent. Vous pouvez faire cela dans votre profil.",
'newcasino.auth.registration.completed.title': 'Enregistrement complété',
'newcasino.auth.registration.cred.already': 'Vous avez déjà un compte?',
'newcasino.auth.registration.cred.back': 'Retour',
'newcasino.auth.registration.cred.email.error': 'Courriel Invalide',
'newcasino.auth.registration.cred.email.hint': 'Entrer votre courriel ici',
'newcasino.auth.registration.cred.email.label': 'Courriel',
'newcasino.auth.registration.cred.license1': "J'accepte les",
'newcasino.auth.registration.cred.license2': 'termes et conditions',
'newcasino.auth.registration.cred.license3': 'et la',
'newcasino.auth.registration.cred.license4': 'politique de confidentialité *',
'newcasino.auth.registration.cred.next': 'Continuer 2/5',
'newcasino.auth.registration.cred.password.button.submit': 'Soumettre',
'newcasino.auth.registration.cred.password.error': 'Le mot de passe doit avoir au moins 8 caractères avec majuscule et minuscule',
'newcasino.auth.registration.cred.password.hint': 'Entrer votre nouveau mot de passe ici',
'newcasino.auth.registration.cred.password.label': 'Mot de passe',
'newcasino.auth.registration.cred.promotion.error': 'Code Promotionnel Invalide',
'newcasino.auth.registration.cred.promotion.hint': 'Entrer votre code promotionnel ici',
'newcasino.auth.registration.cred.promotion.label': 'Code Promotionnel',
'newcasino.auth.registration.cred.subTitle': 'Entrer les informations requises',
'newcasino.auth.registration.cred.title': 'Enregistrement',
'newcasino.auth.registration.currency.australia': 'Dollar australien',
'newcasino.auth.registration.currency.brazil': 'Réal brésilien',
'newcasino.auth.registration.currency.canada': 'Dollar canadien',
'newcasino.auth.registration.currency.europe': 'Euro européen',
'newcasino.auth.registration.currency.usa': 'Dollar américain',
'newcasino.auth.registration.emailcode.already': 'Vous avez déjà un compte?',
'newcasino.auth.registration.emailcode.back': 'Retour',
'newcasino.auth.registration.emailcode.hint': 'Entrer votre code ici',
'newcasino.auth.registration.emailcode.label': 'Code',
'newcasino.auth.registration.emailcode.next': 'Terminer',
'newcasino.auth.registration.emailcode.resend': "Vous n'avez pas reçu le courriel",
'newcasino.auth.registration.emailcode.resend.link': "Cliquez ici pour l'envoyer à nouveau",
'newcasino.auth.registration.emailcode.subTitle': 'Nous venons de vous envoyer un code à votre adresse de courriel. Entrer le code reçu pour compléter votre enregistrement.',
'newcasino.auth.registration.emailcode.title': 'Enregistrement',
'newcasino.auth.registration.mobilecode.already': 'Vous avez déjà un compte?',
'newcasino.auth.registration.mobilecode.back': 'Retour',
'newcasino.auth.registration.mobilecode.hint': 'Entrer votre code ici',
'newcasino.auth.registration.mobilecode.label': 'Code',
'newcasino.auth.registration.mobilecode.next': 'Continuer 5/5',
'newcasino.auth.registration.mobilecode.resend': "Vous n'avez pas reçu le sms?",
'newcasino.auth.registration.mobilecode.resend.link': "Cliquez ici pour l'envoyer à nouveau",
'newcasino.auth.registration.mobilecode.subTitle': 'Nous venons de vous envoyer un code par message texte sur votre téléphone. Entrer le code reçu pour poursuivre votre enregistrement.',
'newcasino.auth.registration.mobilecode.title': 'Enregistrement',
'newcasino.auth.registration.userinfo.already': 'Vous avez déjà un compte?',
'newcasino.auth.registration.userinfo.back': 'Retour',
'newcasino.auth.registration.userinfo.birthday.error': 'Anniversaire invalide - 18+',
'newcasino.auth.registration.userinfo.birthday.hint': 'Ex.: jj/mm/aaaa',
'newcasino.auth.registration.userinfo.birthday.label': "Date d'anniversaire",
'newcasino.auth.registration.userinfo.firstname.error': 'Votre prénom est trop court',
'newcasino.auth.registration.userinfo.firstname.hint': 'Entrer votre prénom ici',
'newcasino.auth.registration.userinfo.firstname.label': 'Prénom',
'newcasino.auth.registration.userinfo.lastname.error': 'Votre nom de famille est trop court',
'newcasino.auth.registration.userinfo.lastname.hint': 'Entrer votre nom de famille ici',
'newcasino.auth.registration.userinfo.lastname.label': 'Nom de famille',
'newcasino.auth.registration.userinfo.next': 'Continuer 3/5',
'newcasino.auth.registration.userinfo.subTitle': 'Entrer les informations requises',
'newcasino.auth.registration.userinfo.title': 'Enregistrement',
'newcasino.batch.admin.button.wager': 'Ajouter des Bonus',
'newcasino.batch.admin.title': 'Travaux en lot',
'newcasino.birthday.gift': 'JOYEUX ANNIVERSAIRE!!! Pour votre anniversaire, nous vous offrons {QTE} tours gratuits.',
'newcasino.cashier.history.col1': 'Date',
'newcasino.cashier.history.col2': 'Type de transaction',
'newcasino.cashier.history.col3': 'Méthode',
'newcasino.cashier.history.col4': 'Montant',
'newcasino.cashier.history.col5': 'Statut',
'newcasino.cashier.history.subTitle': 'Voici vos transactions des 30 derniers jours',
'newcasino.cashier.history.title': 'Historique des transactions',
'newcasino.cashier.history.tx.cancelled': 'Annulée',
'newcasino.cashier.history.tx.completed': 'Complétée',
'newcasino.cashier.history.tx.deposit': 'Dépôt',
'newcasino.cashier.history.tx.pending': 'En attente',
'newcasino.cashier.history.tx.withdrawal': 'Retrait',
'newcasino.cashier.subtitle': "D\'ici, vous pouvez effectuer des dépôts et des retraits d\'argent",
'newcasino.cashier.tab.back': 'Retour',
'newcasino.cashier.tab.history': 'Historique des transactions',
'newcasino.cashier.title': 'CAISSIER',
'newcasino.cashier.vault': 'Voûte',
'newcasino.contactus.button.send': 'Envoyer le message',
'newcasino.contactus.captcha': 'Vous devez cocher le Captcha',
'newcasino.contactus.email.error': 'Courriel invalide',
'newcasino.contactus.failure': "Le message n'a pas pu être envoyé",
'newcasino.contactus.message': 'Message',
'newcasino.contactus.message.error': 'Message trop court',
'newcasino.contactus.message.hint': 'Message',
'newcasino.contactus.name': 'Nom complet',
'newcasino.contactus.name.email': 'Courriel',
'newcasino.contactus.name.email.hint': 'Votre courriel',
'newcasino.contactus.name.error': 'Votre nom est trop court',
'newcasino.contactus.name.hint': 'Entrer votre nom ici',
'newcasino.contactus.question': 'UNE QUESTION? CONTACTEZ-NOUS!',
'newcasino.contactus.subject': 'Sujet',
'newcasino.contactus.subject.error': 'Sujet trop court',
'newcasino.contactus.subject.hint': 'Sujet',
'newcasino.contactus.success': 'Merci, nous avons bien reçu votre message',
'newcasino.contactus.title': 'Contactez-nous',
'newcasino.contactus.where': 'OÙ NOUS TROUVER?',
'newcasino.contests.admin.detail.active': 'Actif',
'newcasino.contests.admin.detail.bullet.en': 'Element chiffré en anglais',
'newcasino.contests.admin.detail.bullet.en.hint': 'Taper en anglais',
'newcasino.contests.admin.detail.bullet.fr': 'Element chiffré en français',
'newcasino.contests.admin.detail.bullet.fr.hint': 'Taper en français',
'newcasino.contests.admin.detail.bullet.pt': 'Element chiffré en portugais',
'newcasino.contests.admin.detail.bullet.pt.hint': 'Taper en portugais',
'newcasino.contests.admin.detail.button.add': '+',
'newcasino.contests.admin.detail.button.delete': 'Supprimer',
'newcasino.contests.admin.detail.button.save': 'Sauvegarder',
'newcasino.contests.admin.detail.date.end': 'Fin du concours',
'newcasino.contests.admin.detail.date.end.hint': 'Ex: dd/mm/yyyy',
'newcasino.contests.admin.detail.date.start': 'Début du concours',
'newcasino.contests.admin.detail.date.start.hint': 'Ex: dd/mm/yyyy',
'newcasino.contests.admin.detail.desc.en': 'Description en anglais',
'newcasino.contests.admin.detail.desc.en.hint': 'Taper la description en anglais',
'newcasino.contests.admin.detail.desc.fr': 'Desciption en français',
'newcasino.contests.admin.detail.desc.fr.hint': 'Taper la description en français',
'newcasino.contests.admin.detail.desc.pt': 'Desciption en portugais',
'newcasino.contests.admin.detail.desc.pt.hint': 'Taper la description en portugais',
'newcasino.contests.admin.detail.header.title.missing': '???',
'newcasino.contests.admin.detail.header.winners': 'Les gagnants',
'newcasino.contests.admin.detail.title.en': 'Titre en anglais',
'newcasino.contests.admin.detail.title.en.hint': 'Taper le titre en anglais',
'newcasino.contests.admin.detail.title.fr': 'Titre en français',
'newcasino.contests.admin.detail.title.fr.hint': 'Taper le titre en français',
'newcasino.contests.admin.detail.title.pt': 'Titre en portugais',
'newcasino.contests.admin.detail.title.pt.hint': 'Taper le titre en portugais',
'newcasino.contests.admin.detail.win.button.add': '+',
'newcasino.contests.admin.detail.win.date': 'Date',
'newcasino.contests.admin.detail.win.date.hint': 'Ex: dd/mm/yyyy',
'newcasino.contests.admin.detail.win.name': 'Nom du gagnant',
'newcasino.contests.admin.detail.win.name.hint': 'Taper le nom du gagnant',
'newcasino.contests.admin.detail.win.prize.en': 'Prix en anglais',
'newcasino.contests.admin.detail.win.prize.en.hint': 'Taper le prix en anglais',
'newcasino.contests.admin.detail.win.prize.fr': 'Prix en français',
'newcasino.contests.admin.detail.win.prize.fr.hint': 'Taper le prix en francais',
'newcasino.contests.admin.detail.win.prize.pt': 'Prix en portugais',
'newcasino.contests.admin.detail.win.prize.pt.hint': 'Taper le prix en portugais',
'newcasino.contests.admin.list.add.button': 'Nouveau concours',
'newcasino.contests.admin.list.col1': 'Date du concours',
'newcasino.contests.admin.list.col2': 'Nom du concours',
'newcasino.contests.admin.list.col3': 'Afficher',
'newcasino.contests.admin.list.no': 'Non',
'newcasino.contests.admin.list.title': 'Gestion des concours',
'newcasino.contests.admin.list.yes': 'Oui',
'newcasino.contests.other': 'Autres concours',
'newcasino.contests.winner.no': 'Gagnant de concours no.',
'newcasino.contests.winner.none': "Aucun gagnant jusqu'à maintenant!",
'newcasino.contests.winner.none2': 'Les gagnants seront affichés ici',
'newcasino.contests.winner.of': 'Gagnant du prix :',
'newcasino.days.1': 'Dimanches',
'newcasino.days.2': 'Lundis',
'newcasino.days.3': 'Mardis',
'newcasino.days.4': 'Mercredis',
'newcasino.days.5': 'Jeudis',
'newcasino.days.6': 'Vendredis',
'newcasino.days.7': 'Samedis',
'newcasino.error.404': 'La page demandée est introuvable',
'newcasino.error.500': 'Oups... Impossible de contacter le serveur, veuillez essayer dans quelques instants',
'newcasino.error.contact.support': 'Une erreur est survenue, SVP contacter le support.',
'newcasino.error.delete.contest': "Il nous est impossible d'effacer ce concours",
'newcasino.error.delete.popup': "Erreur lors de l'opération",
'newcasino.error.email.send.failed': "Il nous a été impossible d'envoyer un code sur votre adresse courriel, SVP contacter le support.",
'newcasino.error.email.send.success': 'Le courriel a été renvoyé. Veuillez vérifier vos spams si vous ne le trouvez pas.',
'newcasino.error.email.validation.failed': 'Ce code est invalide, SVP réessayer',
'newcasino.error.fetching.contest': "Il nous est impossible d'afficher les résultats de concours pour le moment.",
'newcasino.error.fetching.popup': 'Impossible de charger le popup',
'newcasino.error.game.not.available': 'Jeu non disponible',
'newcasino.error.game.not.available.msg': "Ce jeu n'est pas disponible pour le moment.",
'newcasino.error.need.account': 'Connexion nécessaire',
'newcasino.error.need.account.to.play': 'Vous devez avoir un compte et être connecté pour accéder à ce jeu',
'newcasino.error.no.contest': "Il n'y a aucun concours en ce moment",
'newcasino.error.save.contest': 'Impossible de sauvegarder le concours',
'newcasino.error.save.popup': "Erreur lors de l'opération",
'newcasino.error.sms.send.failed': "Il nous a été impossible d'envoyer un code sms à votre numéro, SVP contacter le support.",
'newcasino.error.sms.send.success': 'Le sms a été renvoyé sur votre téléphone',
'newcasino.error.sms.validation.failed': 'Ce code est invalide, svp réessayer',
'newcasino.error.title': 'Erreur',
'newcasino.error.user.creation.failed': "Ce courriel est déjà utilisé. Si c'est le vôtre, vous pouvez simplement réinitialiser le mot de passe.",
'newcasino.faq.a1': "Cliquez sur l'onglet de connexion à partir du bouton de profil puis sur 'Mot de passe oublié ?'. À partir de là, suivez les étapes et vous aurez récupéré votre mot de passe.",
'newcasino.faq.a2': 'Vous pouvez modifier votre mot de passe à tout moment. Connectez-vous simplement à votre compte, allez sur la page « Modifier le profil » et cliquez sur « Modifier le mot de passe ». Vous devrez saisir votre ancien mot de passe ainsi que le nouveau que vous avez sélectionné.',
'newcasino.faq.a3': 'Toutes les principales cartes de crédit, cartes prépayées et crypto-monnaies.',
'newcasino.faq.a4': "Certaines institutions financières n'acceptent pas les transactions provenant des sites Internet « E-Gaming » ; vous devriez donc essayer une autre carte ou un autre mode de paiement. La plupart des cartes de crédit dotées de programmes de récompenses en espèces ne fonctionneront probablement pas sur le site.",
'newcasino.faq.a5': "Lorsque vous accédez à Numbers Game, une notification contextuelle apparaîtra et vous demandera si vous souhaitez ajouter Numbers Game à votre écran d'accueil. Cliquez simplement sur ce lien et l'icône du jeu de nombres apparaîtra sur la page d'accueil de votre téléphone.",
'newcasino.faq.contact': 'Contactez-nous',
'newcasino.faq.hesitate': "N'hésitez pas à nous contacter pour de plus amples informations.",
'newcasino.faq.more': 'UNE AUTRE QUESTION?',
'newcasino.faq.q1': "Que dois-je faire si j'ai oublié le mot de passe de mon compte?",
'newcasino.faq.q2': 'Puis-je changer mon mot de passe?',
'newcasino.faq.q3': 'Quels modes de paiement puis-je utiliser pour effectuer un dépôt?',
'newcasino.faq.q4': 'Ma carte de crédit a été refusée pour une raison inconnue, que dois-je faire?',
'newcasino.faq.q5': 'Je souhaite accéder plus rapidement à Numbers Game. Que puis-je faire?',
'newcasino.faq.title': 'FAQ',
'newcasino.footer.col1.address': 'Premier Étage, Millennium House Victoria Road, Douglas, IM24RW Île de Man',
'newcasino.footer.col1.contactus': 'Contactez-nous',
'newcasino.footer.col2.learnmore': 'En savoir plus',
'newcasino.footer.col2.text': "Numbers Games est détenue par GAC Group Limited et exploitée sous la licence de jeu Kahnawake de GAC Group Limited, une société constituée sur l'île de Man.",
'newcasino.footer.col2.title': 'À propos de nous',
'newcasino.footer.col3.link.aboutus': 'À propos de nous',
'newcasino.footer.col3.link.casino': 'Machines à sous',
'newcasino.footer.col3.link.contact': 'Contact',
'newcasino.footer.col3.link.home': 'Accueil',
'newcasino.footer.col3.link.livecasino': 'Casino en direct',
'newcasino.footer.col3.link.login': 'Connexion',
'newcasino.footer.col3.link.register': 'Enregistrement',
'newcasino.footer.col3.title': 'Numbers Game',
'newcasino.footer.col4.link.affiliate': 'Affiliates',
'newcasino.footer.col4.link.betting': 'Règles de paris',
'newcasino.footer.col4.link.bonusterms': 'Conditions des bonus',
'newcasino.footer.col4.link.faq': 'FAQ',
'newcasino.footer.col4.link.privacy': 'Politique de confidentialité',
'newcasino.footer.col4.link.terms': 'Termes et conditions',
'newcasino.footer.col4.title': 'Liens Importants',
'newcasino.footer.copy.line1': "Numbers Games est détenue par GAC Group Limited et exploitée sous la licence de jeu Kahnawake de GAC Group Limited, une société constituée sur l'île de Man.",
'newcasino.footer.copy.line2': '© 2024 Numbers Game. Tous droits réservés.',
'newcasino.game.popup.BET_TOO_HIGH': 'Mise refusée! La mise maximale pour ce jeu est de {MAXBET}.',
'newcasino.game.popup.DEPOSIT_LIMIT_1000': 'Avec ce montant, vous dépasser votre limite de dépôt quotidienne',
'newcasino.game.popup.DEPOSIT_LIMIT_1001': 'Avec ce montant, vous dépassez votre limite de dépôt hebdomadaire',
'newcasino.game.popup.DEPOSIT_LIMIT_1002': 'Avec ce montant, vous dépassez votre limite de dépôt mensuelle',
'newcasino.game.popup.EXTRA_MONEY_ON_DEPOSIT': 'Félicitation!!! Vous venez de gagner {AMOUNT} de plus sur votre dépôt.',
'newcasino.game.popup.LOST_LIMIT_REACHED': 'Mise refusée! Avec cette mise vous allez dépasser votre limite de perte. Jouer avec prudence.',
'newcasino.game.popup.MAINTENANCE': 'La Casino est en maintenance, revenez dans quelques minutes.',
'newcasino.game.popup.STREAMER_WAGER_WIN': 'Toutes nos félicitations! Vous avez rempli avec succès les conditions du bonus. Le solde a été transféré dans votre coffre-fort.',
'newcasino.game.popup.WAGER_LOSE': 'Meilleure chance la prochaine fois! Vous avez atteint le niveau minimum du solde de mise. Ce portefeuille sera supprimé dans les 24 heures',
'newcasino.game.popup.WAGER_WIN': 'Toutes nos félicitations! Vous avez rempli avec succès les conditions du bonus. Le solde a été transféré dans votre portefeuille personnel.',
'newcasino.games.category.code_all_slots': 'Toutes les machines à sous',
'newcasino.games.category.code_baccarat': 'Baccarat',
'newcasino.games.category.code_blackjack': 'BlackJack',
'newcasino.games.category.code_buy_feature': 'Buy Feature',
'newcasino.games.category.code_crash': 'Jeux de crash',
'newcasino.games.category.code_game_shows': 'Jeux télévisés',
'newcasino.games.category.code_instant_win': 'Gagnant instantané',
'newcasino.games.category.code_live_casino': 'Casino en direct',
'newcasino.games.category.code_lottery': 'Lotterie',
'newcasino.games.category.code_mega_slots': 'Mega Machines à sous',
'newcasino.games.category.code_more_games': 'Plus de jeux',
'newcasino.games.category.code_multiplayer': 'Multijoueurs',
'newcasino.games.category.code_our_selection': 'Notre Sélection',
'newcasino.games.category.code_roulette': 'Roulette',
'newcasino.games.category.code_scratch_card': 'Cartes à gratter',
'newcasino.games.category.code_slots': 'Machines à sous',
'newcasino.games.category.code_tables_cards': 'Tables & Cartes',
'newcasino.games.category.code_top_favorites': 'Les Favoris',
'newcasino.games.category.code_top_games': 'Meilleurs Jeux',
'newcasino.games.category.code_top_live': 'Top Live',
'newcasino.games.category.code_video': 'Vidéo',
'newcasino.games.category.code_video_bingo_keno': 'Vidéo Bingo & Keno',
'newcasino.games.category.code_virtual_sports': 'Sports virtuels',
'newcasino.games.category.code_win_or_crash': 'Gagner ou perdre',
'newcasino.games.newgame': 'Nouveaux jeux',
'newcasino.games.subtype.code_baccara': 'Baccara',
'newcasino.games.subtype.code_baccarat': 'Baccara',
'newcasino.games.subtype.code_blackjack': 'BlackJack',
'newcasino.games.subtype.code_craps': 'Jeux de dés',
'newcasino.games.subtype.code_crash': 'Jeux de crash',
'newcasino.games.subtype.code_live': 'Jeux en direct',
'newcasino.games.subtype.code_others': 'Autres',
'newcasino.games.subtype.code_roulette': 'Roulette',
'newcasino.games.subtype.code_slot': 'Machines à sous',
'newcasino.games.type.code_casino': 'Casino',
'newcasino.games.type.code_livedealer': 'Croupiers en direct',
'newcasino.general.false': 'Faux',
'newcasino.general.true': 'Vrai',
'newcasino.generic.error.msg': 'Une erreur est survenue. Veuillez contacter le support',
'newcasino.giveaways.admin.none': "Il n'y a aucun don en attente",
'newcasino.giveaways.admin.save': 'Sauvegarder',
'newcasino.giveaways.admin.title': 'Dons des influenceurs',
'newcasino.giveaways.amount.hint': 'Montant',
'newcasino.giveaways.amount.label': 'Don',
'newcasino.giveaways.email.error': 'Courriel Invalide',
'newcasino.giveaways.email.hint': 'Courriel',
'newcasino.giveaways.email.label': 'Courriel du fan',
'newcasino.giveaways.failure.amount': "Vous ne semblez pas avoir assez d'argent pour faire ce don",
'newcasino.giveaways.failure.email': 'Le courriel du fan est invalide',
'newcasino.giveaways.failure.wager': "Il semble que vous avez déjà utilisé un contrat aujourd'hui",
'newcasino.giveaways.success.message': 'La demande de don est effectuée',
'newcasino.giveaways.success.wager': 'Le nouveau portefeuille est maintenant disponible',
'newcasino.header.home': 'NUMBERS GAME - CASINO',
'newcasino.homegame.freespin': 'Tours gratuits',
'newcasino.homegame.freespin.sublabel': "Vous disposez de {QTE} tours gratuits que vous pouvez utiliser sur l'un des jeux suivants",
'newcasino.homegame.live': 'Croupiers en direct',
'newcasino.homegame.lobby': 'Lobby',
'newcasino.homegame.providers': 'Fournisseurs',
'newcasino.homegame.search': 'Rechercher',
'newcasino.homegame.showall': 'Tout afficher',
'newcasino.homegame.slots': 'Machines à sous',
'newcasino.info.title': 'Info',
'newcasino.loyalty.claim.success.freespin': 'Vous venez de gagner {QTE} tours gratuits. Sur la page principale, lorsque votre portefeuille en argent réel est sélectionné, vous verrez un onglet de tours gratuits affichant tous les jeux où vous pouvez les utiliser.',
'newcasino.loyalty.claim.success.loyalty': 'Vous gagnez {QTE} points PNG',
'newcasino.loyalty.claim.success.money': 'Vous venez de gagner {AMOUNT}',
'newcasino.loyalty.claim.success.none': 'La boîte est vide, meilleure chance demain',
'newcasino.loyalty.claim.success.wager': 'Vous gagnez un portefeuille de paris bonus : {AMOUNT} {X}x - Gain Maximum: {MAXWIN}',
'newcasino.loyalty.daily.continue': 'Pour continuer à ouvrir les cadeaux quotidiens le mois prochain, vous devrez obtenir le minimum',
'newcasino.loyalty.daily.enable': 'Points PNG pour activer cette fonctionnalité',
'newcasino.loyalty.daily.first': 'Le premier mois est ouvert à tous. Vous pouvez récupérer un cadeau quotidien dès maintenant en cliquant dessus.',
'newcasino.loyalty.daily.minimum': 'Il faut atteindre le minimum',
'newcasino.loyalty.daily.na': "Votre récompense quotidienne n'est pas disponible ce mois-ci.",
'newcasino.loyalty.daily.nextin': 'Cadeau disponible dans:',
'newcasino.loyalty.daily.no.kyc': "Vous devez soumettre vos documents d'identité dans « Mon profil » et être approuvé pour commencer à recevoir vos cadeaux quotidiens.",
'newcasino.loyalty.daily.points': 'points',
'newcasino.loyalty.daily.timeleft': 'Temps restant pour réclamer:',
'newcasino.loyalty.daily.title': 'Récompense consécutive quotidienne',
'newcasino.loyalty.day1': 'Jour 1',
'newcasino.loyalty.day2': 'Jour 2',
'newcasino.loyalty.day3': 'Jour 3',
'newcasino.loyalty.day4': 'Jour 4',
'newcasino.loyalty.day5': 'Jour 5',
'newcasino.loyalty.day6': 'Jour 6',
'newcasino.loyalty.day7': 'Jour 7',
'newcasino.loyalty.gift.confirmation.title': 'Félicitations !!!',
'newcasino.loyalty.how': 'Comment ça fonctionne?',
'newcasino.loyalty.how.para.1.title': 'Points de récompense PNG',
'newcasino.loyalty.how.para.1.txt': "Vous pouvez échanger vos points contre de l'argent réel, des tours gratuits ou même de l'argent réel avec une condition de mise.",
'newcasino.loyalty.how.para.2.title': 'Points de progression',
'newcasino.loyalty.how.para.2.txt': 'Les points de progression déterminent le niveau VIP dans lequel vous vous situez.',
'newcasino.loyalty.how.para.3.title': 'Boutique de Point PNG',
'newcasino.loyalty.how.para.3.txt': "Vous pouvez échanger vos points de récompense PNG ici contre l'option qui vous convient le mieux : argent réel, tours gratuits ou argent réel avec une condition de mise.",
'newcasino.loyalty.how.para.3.txtextra': "*Si vous échangez vos points de récompense PNG contre de l'argent réel avec une condition de mise, veuillez noter que les conditions de mise diminuent de 5X à chaque nouveau niveau VIP pour le même prix.",
'newcasino.loyalty.how.para.4.title': 'Niveaux VIP Numbers Game',
'newcasino.loyalty.how.para.4.txt': 'Au fur et à mesure que vous gravirez chaque niveau, vos récompenses augmenteront, vous offrant des prix de plus en plus attrayants à chaque étape de votre voyage.',
'newcasino.loyalty.how.para.5.title': 'Récompenses de visites consécutives quotidiennes',
'newcasino.loyalty.how.para.5.txt': "En gagnant un minimum de 50 points de progression chaque mois, vous débloquez vos récompenses de visites consécutives quotidiennes pour le mois suivant. Chaque jour qui passe et à mesure que vous avancez dans les niveaux VIP, des récompenses supérieures s'offriront à vous. Plus vous jouez, meilleur est le niveau des prix.",
'newcasino.loyalty.how.para.6.table.head.1': 'Pour chaque 5 EUR misés',
'newcasino.loyalty.how.para.6.table.head.2': 'Points de récompense PNG',
'newcasino.loyalty.how.para.6.table.head.3': 'Points de progression',
'newcasino.loyalty.how.para.6.table.value.1': 'Machines à sous',
'newcasino.loyalty.how.para.6.table.value.2': '1',
'newcasino.loyalty.how.para.6.table.value.3': '1',
'newcasino.loyalty.how.para.6.title': 'Comment gagner',
'newcasino.loyalty.how.para.7.table.head.1': 'Pour chaque 50 EUR misés',
'newcasino.loyalty.how.para.7.table.head.2': 'Points de récompense PNG',
'newcasino.loyalty.how.para.7.table.head.3': 'Points de progression',
'newcasino.loyalty.how.para.7.table.value.1': 'Jeux avec croupier en direct',
'newcasino.loyalty.how.para.7.table.value.2': '1',
'newcasino.loyalty.how.para.7.table.value.3': '1',
'newcasino.loyalty.how.para.7.table.value.4': 'Jeux de table',
'newcasino.loyalty.how.para.7.table.value.5': '1',
'newcasino.loyalty.how.para.7.table.value.6': '1',
'newcasino.loyalty.how.para.7.table.value.7': 'Jeux de crash',
'newcasino.loyalty.how.para.7.table.value.8': '1',
'newcasino.loyalty.how.para.7.table.value.9': '1',
'newcasino.loyalty.how.title': 'Comment ça fonctionne?',
'newcasino.loyalty.level.1': 'Niveau de base',
'newcasino.loyalty.level.1.color': '#E6824E',
'newcasino.loyalty.level.10': 'Niveau IX',
'newcasino.loyalty.level.10.color': '#FFD700',
'newcasino.loyalty.level.11': 'Niveau X',
'newcasino.loyalty.level.11.color': '#E5E4E2',
'newcasino.loyalty.level.2': 'Niveau I',
'newcasino.loyalty.level.2.color': '#A1BFC2',
'newcasino.loyalty.level.3': 'Niveau II',
'newcasino.loyalty.level.3.color': '#EACD5B',
'newcasino.loyalty.level.4': 'Niveau III',
'newcasino.loyalty.level.4.color': '#C3A7D0',
'newcasino.loyalty.level.5': 'Niveau IV',
'newcasino.loyalty.level.5.color': '#F5F5F5',
'newcasino.loyalty.level.6': 'Niveau V',
'newcasino.loyalty.level.6.color': '#EB0BAC',
'newcasino.loyalty.level.7': 'Niveau VI',
'newcasino.loyalty.level.7.color': '#b87333',
'newcasino.loyalty.level.8': 'Niveau VII',
'newcasino.loyalty.level.8.color': '#b08d57',
'newcasino.loyalty.level.9': 'Niveau VIII',
'newcasino.loyalty.level.9.color': '#C0C0C0',
'newcasino.loyalty.level.title': 'Niveau VIP',
'newcasino.loyalty.mylevel': 'Mon niveau',
'newcasino.loyalty.mypoint': 'Mes points',
'newcasino.loyalty.no.points': 'Il ne vous reste plus assez de points pour acheter quoi que ce soit',
'newcasino.loyalty.progression': 'Progression des points PNG pour activer les récompenses quotidiennes du mois prochain',
'newcasino.loyalty.progression.percent': 'Progression vers niveau suivant',
'newcasino.loyalty.shop.cancel': 'Annuler',
'newcasino.loyalty.shop.category.1': 'Tout',
'newcasino.loyalty.shop.category.2': 'Argent',
'newcasino.loyalty.shop.category.3': 'Portefeuilles de pari',
'newcasino.loyalty.shop.category.4': 'Tours gratuits',
'newcasino.loyalty.shop.category.5': 'Tout ce que je peux me permettre',
'newcasino.loyalty.shop.confirm': 'Confirmer',
'newcasino.loyalty.shop.confirm.cash': "Vous venez d'acheter:",
'newcasino.loyalty.shop.confirm.freespin.1': "Vous venez d'acheter",
'newcasino.loyalty.shop.confirm.freespin.2': 'Tours gratuits. Sur la page principale, lorsque votre portefeuille en argent réel est sélectionné, vous verrez un onglet de tours gratuits affichant tous les jeux où vous pouvez les utiliser.',
'newcasino.loyalty.shop.confirm.freespin.usethemnow': 'Utiliser les maintenant',
'newcasino.loyalty.shop.confirm.wager.1': "Vous venez d'acheter un portefeuille de paris de",
'newcasino.loyalty.shop.confirm.wager.2': 'avec un requis de',
'newcasino.loyalty.shop.confirm.wager.3': 'x.',
'newcasino.loyalty.shop.element.avail': 'Disponible',
'newcasino.loyalty.shop.element.cost': 'Coût:',
'newcasino.loyalty.shop.element.freespin': 'Tours gratuits',
'newcasino.loyalty.shop.element.na': 'Pas assez de PTS',
'newcasino.loyalty.shop.element.pts': 'PTS',
'newcasino.loyalty.shop.mainpage.button': 'Magasiner maintenant!',
'newcasino.loyalty.shop.mainpage.title.1': 'Tours gratuits',
'newcasino.loyalty.shop.mainpage.title.2': 'Argent',
'newcasino.loyalty.shop.mainpage.title.3': 'Montant avec cible',
'newcasino.loyalty.shop.mainpage.txt.1': 'Oui!!! Vous pouvez acheter des tours gratuits avec vos points PNG',
'newcasino.loyalty.shop.mainpage.txt.2': 'Vous pouvez convertir vos points en argent réel !!!',
'newcasino.loyalty.shop.mainpage.txt.3': 'Des portefeuilles de paris sont également disponibles. Le facteur x diminue à chaque niveau de fidélité.',
'newcasino.loyalty.shop.no.kyc': "Vous devez soumettre vos documents d'identité dans « Mon profil » et être approuvé pour commencer à échanger vos points PNG.",
'newcasino.loyalty.shop.subtitle': 'Échangez vos points PNG contre le bonus de votre choix ! Sélectionnez une récompense et cliquez sur le bouton de confirmation.',
'newcasino.loyalty.shop.title': 'Boutique de récompenses PNG',
'newcasino.loyalty.title': 'Récompenses PNG',
'newcasino.loyalty.vip.features.10percextra': '10 % de points de récompense PNG supplémentaires',
'newcasino.loyalty.vip.features.5xwb': '5x de moins pour le pari acheté',
'newcasino.loyalty.vip.features.birth': "Cadeau d'anniversaire",
'newcasino.loyalty.vip.features.bonusGrowth': 'Croissance bonus',
'newcasino.loyalty.vip.features.dailyBonusReload': 'Bonus quotidiens/Recharge',
'newcasino.loyalty.vip.features.dr': 'Récompenses quotidiennes',
'newcasino.loyalty.vip.features.levelUpBonuses': 'Bonus de mise à niveau',
'newcasino.loyalty.vip.features.monthlyBonuses': 'Bonus mensuels',
'newcasino.loyalty.vip.features.rakeback': 'Rakeback',
'newcasino.loyalty.vip.features.rs': 'Boutique de récompenses PNG',
'newcasino.loyalty.vip.features.weeklyBonuses': 'Bonus hebdomadaires',
'newcasino.memorylist.next': 'Page suivante',
'newcasino.memorylist.of': 'de',
'newcasino.memorylist.page': 'Page',
'newcasino.memorylist.previous': 'Page précédente',
'newcasino.menu.wallets.bonus': 'Bonus',
'newcasino.menu.wallets.mywallet': 'Mon argent',
'newcasino.menu.wallets.streamer': 'Influenceur',
'newcasino.menu.wallets.switch.ko': 'Impossible de changer de portefeuille.',
'newcasino.menu.wallets.switch.ok': 'Le portefeuille a bien été sélectionné.',
'newcasino.menu.wallets.title': 'Vos portefeuilles:',
'newcasino.menuAdmin.1': 'Gestion des utilisateurs',
'newcasino.menuAdmin.10': 'Messages Textes',
'newcasino.menuAdmin.2': 'Gestion des concours',
'newcasino.menuAdmin.3': 'Orders',
'newcasino.menuAdmin.4': 'Streamers',
'newcasino.menuAdmin.5': 'Giveaways',
'newcasino.menuAdmin.6': 'Jeux',
'newcasino.menuAdmin.7': 'Gestion des Popups',
'newcasino.menuAdmin.8': 'Maintenance',
'newcasino.menuAdmin.9': 'Promotions',
'newcasino.menuAdmin.title': 'Admin Menu',
'newcasino.menuleft.3.101': 'Français',
'newcasino.menuleft.3.102': 'English',
'newcasino.menuleft.3.103': 'Português',
'newcasino.menuleft.3.2': 'Influenceur',
'newcasino.menuleft.3.4': 'Jeu responsable',
'newcasino.menuleft.3.5': 'Résultats Concours',
'newcasino.menuleft.3.6': 'Référez un ami',
'newcasino.menuleft.3.7': 'Loyauté',
'newcasino.menuleft.4.1': 'Cadeaux quotidiens',
'newcasino.menuleft.4.2': 'Boutique PNG',
'newcasino.menuleft.4.3': 'Niveau VIP',
'newcasino.menuleft.category.3': 'Plus',
'newcasino.menuleft.darkmode': 'Mode sombre',
'newcasino.menutop.button.1': 'Connexion',
'newcasino.menutop.button.2': 'Enregistrement',
'newcasino.menutop.button.bank': '$ --',
'newcasino.menutop.button.cashier': 'Caisse',
'newcasino.menutop.lang.en': 'English',
'newcasino.menutop.lang.fr': 'Français',
'newcasino.menutop.lang.pt': 'Português',
'newcasino.menutop.lang.title': 'Langue',
'newcasino.menutop.maximize': 'Format plein écran',
'newcasino.menutop.wallet': 'Sélectionner votre portefeuille',
'newcasino.mobileBottomMenu.element.1': 'Menu',
'newcasino.mobileBottomMenu.element.2': 'Accueil',
'newcasino.mobileBottomMenu.element.3': 'Machines à sous',
'newcasino.mobileBottomMenu.element.4': 'Casino en direct',
'newcasino.mobileBottomMenu.element.5': 'Support',
'newcasino.modal.msg.function.not.available.yet': 'Cette fonction sera bientôt offerte',
'newcasino.modal.msg.info': 'Information',
'newcasino.player.use.freespin.button.no': 'Non',
'newcasino.player.use.freespin.button.yes': 'Oui',
'newcasino.player.use.freespin.title': 'Tours gratuits disponibles',
'newcasino.player.use.freespin.txt1': 'Vous avez',
'newcasino.player.use.freespin.txt2': 'tours gratuits. Voulez-vous les utiliser dans ce jeu?',
'newcasino.popups.admin.detail.active': 'Actif',
'newcasino.popups.admin.detail.body.en': 'Contenu',
'newcasino.popups.admin.detail.body.en.hint': 'Taper le contenu en anglais',
'newcasino.popups.admin.detail.body.fr': 'Contenu',
'newcasino.popups.admin.detail.body.fr.hint': 'Taper le contenu en français',
'newcasino.popups.admin.detail.body.pt': 'Contenu',
'newcasino.popups.admin.detail.body.pt.hint': 'Taper le contenu en portugais',
'newcasino.popups.admin.detail.button.delete': 'Supprimer',
'newcasino.popups.admin.detail.button.save': 'Sauvegarder',
'newcasino.popups.admin.detail.button.test': 'Tester',
'newcasino.popups.admin.detail.date.end': 'Date de fin',
'newcasino.popups.admin.detail.date.end.hint': 'Ex: dd/mm/yyyy',
'newcasino.popups.admin.detail.date.start': 'Date de début',
'newcasino.popups.admin.detail.date.start.hint': 'Ex: dd/mm/yyyy',
'newcasino.popups.admin.detail.header.title.missing': '???',
'newcasino.popups.admin.detail.header.title.popup': 'Edition du Popup',
'newcasino.popups.admin.detail.height': 'Hauteur popup (px)',
'newcasino.popups.admin.detail.height.hint': 'Hauteur (100% par défaut)',
'newcasino.popups.admin.detail.html': 'Utiliser Html',
'newcasino.popups.admin.detail.internal.name': 'Nom interne',
'newcasino.popups.admin.detail.internal.name.hint': 'Taper le nom interne',
'newcasino.popups.admin.detail.receiver.admin': 'Administrateurs',
'newcasino.popups.admin.detail.receiver.affiliates': 'Affiliés',
'newcasino.popups.admin.detail.receiver.all': 'Tous le monde',
'newcasino.popups.admin.detail.receiver.hint': 'Sélectionner le récipiendaire...',
'newcasino.popups.admin.detail.receiver.label': 'Récipiendaire',
'newcasino.popups.admin.detail.receiver.logged': 'Tous les gens loggés',
'newcasino.popups.admin.detail.receiver.partners': 'Partenaires',
'newcasino.popups.admin.detail.receiver.players': 'Joueurs',
'newcasino.popups.admin.detail.receiver.streamers': 'Streamers',
'newcasino.popups.admin.detail.tab.english': 'Anglais',
'newcasino.popups.admin.detail.tab.french': 'Français',
'newcasino.popups.admin.detail.tab.portuguese': 'Portugais',
'newcasino.popups.admin.detail.title.en': 'Titre anglais',
'newcasino.popups.admin.detail.title.en.hint': 'Taper le titre en anglais',
'newcasino.popups.admin.detail.title.fr': 'Titre',
'newcasino.popups.admin.detail.title.fr.hint': 'Taper le titre en français',
'newcasino.popups.admin.detail.title.pt': 'Titre',
'newcasino.popups.admin.detail.title.pt.hint': 'Taper le titre en portugais',
'newcasino.popups.admin.list.add.button': 'Nouveau popup',
'newcasino.popups.admin.list.col1': 'Date de création',
'newcasino.popups.admin.list.col2': 'Nom du popup',
'newcasino.popups.admin.list.col3': 'Actif',
'newcasino.popups.admin.list.no': 'Non',
'newcasino.popups.admin.list.title': 'Gestion des popups',
'newcasino.popups.admin.list.yes': 'Oui',
'newcasino.profile.info.address.address.error': 'Votre adresse est trop courte',
'newcasino.profile.info.address.address.hint': 'Adresse',
'newcasino.profile.info.address.address.label': 'Adresse',
'newcasino.profile.info.address.city.error': 'Trop court',
'newcasino.profile.info.address.city.hint': 'Votre ville',
'newcasino.profile.info.address.city.label': 'Ville',
'newcasino.profile.info.address.country.hint': 'Sélectionner votre pays...',
'newcasino.profile.info.address.country.label': 'Pays',
'newcasino.profile.info.address.state.error': 'Trop court',
'newcasino.profile.info.address.state.hint': 'Votre État/Province',
'newcasino.profile.info.address.state.label': 'État/Province',
'newcasino.profile.info.address.zip.error': 'Trop court',
'newcasino.profile.info.address.zip.hint': 'Code postal/zipcode',
'newcasino.profile.info.address.zip.label': 'Code Postal/Zipcode',
'newcasino.profile.info.button.change': 'Modifier',
'newcasino.profile.info.button.update': 'Mettre à jour',
'newcasino.profile.info.email.label': 'Courriel',
'newcasino.profile.info.fn.label': 'Prénom',
'newcasino.profile.info.ln.label': 'Nom de famille',
'newcasino.profile.info.phone.label': 'No. de téléphone',
'newcasino.profile.info.success': 'Vos informations ont été modifiées.',
'newcasino.profile.password.button': 'Mettre à jour',
'newcasino.profile.password.doc.legacy': 'Images historiques',
'newcasino.profile.password.doc.newimages': 'Nouvelles Images',
'newcasino.profile.password.doc.proof1.approve': 'Approuver',
'newcasino.profile.password.doc.proof1.decline': 'Refuser',
'newcasino.profile.password.doc.proof1.desc': "Passeport ou permis de conduire ou carte d'identité nationale.",
'newcasino.profile.password.doc.proof1.title': "Preuve d'identité",
'newcasino.profile.password.doc.proof2.desc': 'Facture de services publics ou relevé bancaire',
'newcasino.profile.password.doc.proof2.title': 'Justificatif de domicile',
'newcasino.profile.password.doc.select': 'Sélectionnez une image',
'newcasino.profile.password.failure': "Votre mot de passe n'a pas pu être modifié, vérifier votre mot de passe courant.",
'newcasino.profile.password.input1.error': 'Mot de passe trop court',
'newcasino.profile.password.input1.hint': 'Tapez ici votre mot de passe actuel',
'newcasino.profile.password.input1.label': 'Mot de passe actuel',
'newcasino.profile.password.input2.error': "Vous avez besoin d'au moins 8 caractères avec un mélange de majuscules et de minuscules",
'newcasino.profile.password.input2.hint': 'Tapez votre mot de passe ici',
'newcasino.profile.password.input2.label': 'Nouveau mot de passe',
'newcasino.profile.password.success': 'Votre mot de passe a été modifié.',
'newcasino.profile.popup.phone.button.cancel': 'Annuler',
'newcasino.profile.popup.phone.button.end': 'Valider',
'newcasino.profile.popup.phone.button.next': 'Envoyer le SMS',
'newcasino.profile.popup.phone.countrycode.hint': 'Code',
'newcasino.profile.popup.phone.countrycode.label': 'Code de pays',
'newcasino.profile.popup.phone.number.hint': 'Numéro complet',
'newcasino.profile.popup.phone.number.label': 'Numéro complet',
'newcasino.profile.popup.phone.sms.failure.send': "Le message SMS n'a pas pu être envoyé, vérifiez le numéro ou contacter le support",
'newcasino.profile.popup.phone.sms.failure.verify': "Le code entré n'est pas accepté, vérifier le code ou contacter le support",
'newcasino.profile.popup.phone.sms.success.send': 'Le SMS a été envoyé vers votre téléphone',
'newcasino.profile.popup.phone.sms.success.verify': 'Votre numéro a été modifié',
'newcasino.profile.popup.phone.subtitle': 'Pour modifier votre numéro, nous devons le valider en vous envoyant un message SMS.',
'newcasino.profile.popup.phone.title': 'Nouveau Numéro?',
'newcasino.profile.tab.bank': 'Transactions',
'newcasino.profile.tab.docs': 'Documents',
'newcasino.profile.tab.password': 'Mot de passe',
'newcasino.profile.tab.personal': 'Info Générales',
'newcasino.profile.tab.pngpoints': 'Points PNG',
'newcasino.profile.tab.wallets': 'Portefeuilles',
'newcasino.profile.title': 'Mon Profil',
'newcasino.promotions.admin.list.add.button': 'Ajouter',
'newcasino.promotions.admin.list.col0': 'En cours',
'newcasino.promotions.admin.list.col1': 'Nom Promotion',
'newcasino.promotions.admin.list.col2': 'Active',
'newcasino.promotions.admin.list.col3': 'Début',
'newcasino.promotions.admin.list.col4': 'Fin',
'newcasino.promotions.admin.list.title': 'Promotions',
'newcasino.promotions.admin.prom.add.success': 'La promotion a été ajoutée',
'newcasino.promotions.admin.prom.mod.success': 'La promotion a été mise à jour',
'newcasino.promotions.admin.prom.new.deposit.action': 'Action sur montant de dépôt',
'newcasino.promotions.admin.prom.new.deposit.chance': 'Chance de gagner',
'newcasino.promotions.admin.prom.new.deposit.double': 'Doubler',
'newcasino.promotions.admin.prom.new.deposit.enddate': 'Date de fin',
'newcasino.promotions.admin.prom.new.deposit.endtime': 'Heure de fin',
'newcasino.promotions.admin.prom.new.deposit.maxwin': 'Gain Maximum par dépôt',
'newcasino.promotions.admin.prom.new.deposit.select': 'Sélectionner une valeur..',
'newcasino.promotions.admin.prom.new.deposit.startdate': 'Date de début',
'newcasino.promotions.admin.prom.new.deposit.starttime': 'Heure de début',
'newcasino.promotions.admin.prom.new.deposit.total': 'Montant total à distribuer',
'newcasino.promotions.admin.prom.new.deposit.triple': 'Tripler',
'newcasino.promotions.admin.prom.new.gift.audience.all': 'Tous',
'newcasino.promotions.admin.prom.new.gift.audience.select': 'Choisir les destinataires',
'newcasino.promotions.admin.prom.new.gift.audience.specific': 'Télécharger la liste des utilisateurs',
'newcasino.promotions.admin.prom.new.gift.cash': 'Argent',
'newcasino.promotions.admin.prom.new.gift.cash.amount': 'Montant en Euro',
'newcasino.promotions.admin.prom.new.gift.cash.quantity': 'Quantité',
'newcasino.promotions.admin.prom.new.gift.cron.date': "Date d'activation",
'newcasino.promotions.admin.prom.new.gift.cron.time': "Heure d'activation",
'newcasino.promotions.admin.prom.new.gift.freespin': 'Tours gratuits',
'newcasino.promotions.admin.prom.new.gift.freespin.expiration.days': 'Expiration en jours',
'newcasino.promotions.admin.prom.new.gift.freespin.quantity': 'Quantité',
'newcasino.promotions.admin.prom.new.gift.png': 'Points PNG',
'newcasino.promotions.admin.prom.new.gift.select': 'Sélectionner un cadeau',
'newcasino.promotions.admin.prom.new.gift.title': 'Type de cadeau',
'newcasino.promotions.admin.prom.new.gift.wager': 'Bonus',
'newcasino.promotions.admin.prom.new.gift.wager.amount': 'Montant en Euro',
'newcasino.promotions.admin.prom.new.gift.wager.expiration.date': "Date d'expiration",
'newcasino.promotions.admin.prom.new.gift.wager.expiration.time': "Heure d'expiration",
'newcasino.promotions.admin.prom.new.gift.wager.maxwin': 'Gains Maximum',
'newcasino.promotions.admin.prom.new.gift.wager.xtime': 'Multiplicateur',
'newcasino.promotions.admin.prom.new.name': 'Nom de la promotion',
'newcasino.promotions.admin.prom.new.next': 'Continuer',
'newcasino.promotions.admin.prom.new.title': 'Nouvelle Promotions',
'newcasino.promotions.admin.prom.new.type': 'Type de promotion',
'newcasino.promotions.admin.prom.new.type.deposit': 'Bonus de dépôt',
'newcasino.promotions.admin.prom.new.type.instant': 'Cadeau instantané',
'newcasino.promotions.admin.prom.new.type.select': 'Sélectionner un type',
'newcasino.promotions.admin.prom.new.upload': 'Sélectionnez le CSV',
'newcasino.promotions.admin.prom.viewer.active': 'Actif',
'newcasino.promotions.admin.prom.viewer.audience': 'Récipiendaires',
'newcasino.promotions.admin.prom.viewer.audience.all': 'Tous  (Sauf Bannis)',
'newcasino.promotions.admin.prom.viewer.audience.list': 'Liste CSV',
'newcasino.promotions.admin.prom.viewer.button.activate': 'Activer',
'newcasino.promotions.admin.prom.viewer.button.deactivate': 'Désactiver',
'newcasino.promotions.admin.prom.viewer.button.delete': 'Effacer',
'newcasino.promotions.admin.prom.viewer.button.download.original': 'Télécharger la liste',
'newcasino.promotions.admin.prom.viewer.button.download.result': 'Télécharger les résultats',
'newcasino.promotions.admin.prom.viewer.cash.nbr': 'Argent (Euro)',
'newcasino.promotions.admin.prom.viewer.cronned': 'Exécuté (Robot)',
'newcasino.promotions.admin.prom.viewer.deposit.chance': 'Chance de gagner (%)',
'newcasino.promotions.admin.prom.viewer.deposit.end': 'Fin (Heure de Londre)',
'newcasino.promotions.admin.prom.viewer.deposit.max': 'Gain maximal par dépôt',
'newcasino.promotions.admin.prom.viewer.deposit.start': 'Commencement (Heure de Londre)',
'newcasino.promotions.admin.prom.viewer.deposit.stats.wineuro': 'Argent donné',
'newcasino.promotions.admin.prom.viewer.deposit.total': 'Argent total à distribuer',
'newcasino.promotions.admin.prom.viewer.freespins.expiration': 'Expiration en jours',
'newcasino.promotions.admin.prom.viewer.freespins.nbr': 'Tours gratuits',
'newcasino.promotions.admin.prom.viewer.gift': 'Type de cadeau',
'newcasino.promotions.admin.prom.viewer.png.nbr': 'Points PNG',
'newcasino.promotions.admin.prom.viewer.promo.deposit': 'Bonus lors de dépôt',
'newcasino.promotions.admin.prom.viewer.promo.gift': 'Cadeau instantané',
'newcasino.promotions.admin.prom.viewer.type': 'Type de promotion',
'newcasino.promotions.admin.prom.viewer.wager.expDate': "Date d'expiration",
'newcasino.promotions.admin.prom.viewer.wager.maxwin': 'Gain maximal (Euro)',
'newcasino.promotions.admin.prom.viewer.wager.nbr': 'Montant bonus (Euro)',
'newcasino.promotions.admin.prom.viewer.wager.stats.inprogress': 'En cours',
'newcasino.promotions.admin.prom.viewer.wager.stats.looser': 'Nbr Perdants',
'newcasino.promotions.admin.prom.viewer.wager.stats.nbr': 'Bonus donnés',
'newcasino.promotions.admin.prom.viewer.wager.stats.title': 'Statistiques',
'newcasino.promotions.admin.prom.viewer.wager.stats.wineuro': 'Total Gains Euro',
'newcasino.promotions.admin.prom.viewer.wager.stats.winners': 'Nbr Gagnants',
'newcasino.promotions.admin.prom.viewer.wager.x': 'Multiplicateur',
'newcasino.referafriend.button.claim': 'Réclamer!',
'newcasino.referafriend.claim': 'RÉCLAMER MON ARGENT',
'newcasino.referafriend.copied': 'Copié!',
'newcasino.referafriend.copy': 'Copier le lien',
'newcasino.referafriend.header.amount': 'Montant',
'newcasino.referafriend.header.currency': 'Devise',
'newcasino.referafriend.header.name': 'Ami',
'newcasino.referafriend.info.part1': "Gagnez {amountEuro} euros ({localCurrency}) pour chaque invitation réussie. Lorsque votre ami joue son premier dépôt {wagerRequired}, vous pourrez réclamer {amountEuro} euros sur votre solde. Oui, vous pouvez retirer l'argent à tout moment",
'newcasino.referafriend.info.part1-amountEuro': '17',
'newcasino.referafriend.info.part1-minimalDeposit': '$10',
'newcasino.referafriend.info.part1-wagerRequired': '1x',
'newcasino.referafriend.info.part1-wagerRequiredRecipient': '35x',
'newcasino.referafriend.info.part2': 'Votre ami aura la possibilité de choisir son offre de bienvenue.',
'newcasino.referafriend.info.part3': 'Pour réclamer le prix, il vous suffit de cliquer sur « Réclamez mon argent » ici même sur la page Référez un ami.',
'newcasino.referafriend.invite': 'Invitez vos {friends}',
'newcasino.referafriend.invite.part1': 'amis',
'newcasino.referafriend.money.claimed': 'Prix que vous avez réclamés',
'newcasino.referafriend.money.to.claim': 'Prix disponible(s)',
'newcasino.referafriend.nbr.player.deposit': 'Amis qui ont déposés',
'newcasino.referafriend.nbr.player.use.link': 'Amis qui se sont enregistrés',
'newcasino.referafriend.nbr.player.wager': 'Amis qui ont complétés le requis de bonus',
'newcasino.referafriend.popup.close': 'Fermer',
'newcasino.referafriend.popup.text': 'Sélectionner les montants que vous souhaitez utiliser. Si les montants sont en devises étrangères, ils seront automatiquement converti dans votre devise.',
'newcasino.referafriend.popup.title': 'Réclamer mon argent',
'newcasino.referafriend.popup.wait': 'Veuiller Patienter...',
'newcasino.referafriend.title': 'Référez un ami',
'newcasino.referafriend.wager.success': "Bravo!!! Vous venez d'obtenir {money}.",
'newcasino.reset.password.failure': "Votre mot de passe n'a pas pu être réinitialisé. Le lien est peut-être expiré.",
'newcasino.reset.password.password.label': 'Nouveau mot de passe',
'newcasino.reset.password.success': 'Votre mot de passe a été réinitialisé',
'newcasino.reset.password.title': 'Réinitialiser votre mot de passe',
'newcasino.rg.activity.check.page.120': '120 Minutes',
'newcasino.rg.activity.check.page.30': '30 Minutes',
'newcasino.rg.activity.check.page.60': '60 Minutes',
'newcasino.rg.activity.check.page.checkbox': "En cliquant ici, je comprends que je mets en place un contrôle d'activité automatisé",
'newcasino.rg.activity.check.page.subtitle': "NOUS VOULONS QUE VOUS PROFITER DE JOUER DE MANIÈRE RESPONSABLE SUR NOTRE CASINO, C'EST POURQUOI NOUS PROPOSONS UNE FONCTION DE VÉRIFICATION D'ACTIVITÉ. IL VOUS AIDERA À GARDER UNE SUIVI DE VOTRE TEMPS DE JEU EN ENVOYANT DES NOTIFICATIONS RÉGLABLES QUI AGISSENT COMME UN RÉVEIL DE JOUEUR, À PARTIR DU MOMENT DE VOTRE CONNEXION.",
'newcasino.rg.activity.check.page.title': "Vérification d'activité",
'newcasino.rg.activity.check.success.1': 'À partir de maintenant, vous recevrez une notification après',
'newcasino.rg.activity.check.success.2': ' minutes de jeu',
'newcasino.rg.deposit.limit.page.button': 'Définir/Mettre à jour',
'newcasino.rg.deposit.limit.page.daily': 'Votre limite de dépôt quotidienne actuelle est de:',
'newcasino.rg.deposit.limit.page.monthly': 'Votre limite de dépôt mensuelle actuelle est de:',
'newcasino.rg.deposit.limit.page.subtitle': "* Les valeurs ne peuvent être modifiées qu'une fois toutes les 48 heures",
'newcasino.rg.deposit.limit.page.title': 'Limite de dépôt',
'newcasino.rg.deposit.limit.page.weekly': 'Votre limite de dépôt hebdomadaire actuelle est de:',
'newcasino.rg.lost.limit.page.button': 'Définir/Mettre à jour',
'newcasino.rg.lost.limit.page.daily': 'Votre limite de perte quotidienne actuelle est de:',
'newcasino.rg.lost.limit.page.monthly': 'Votre limite de perte mensuelle actuelle est de:',
'newcasino.rg.lost.limit.page.subtitle': "* Les valeurs ne peuvent être modifiées qu'une fois toutes les 48 heures",
'newcasino.rg.lost.limit.page.title': 'Limite de perte',
'newcasino.rg.lost.limit.page.weekly': 'Votre limite de perte hebdomadaire actuelle est de:',
'newcasino.rg.self.exclusion.page.1': '1 An',
'newcasino.rg.self.exclusion.page.3': '3 Ans',
'newcasino.rg.self.exclusion.page.5': '5 Ans',
'newcasino.rg.self.exclusion.page.6': '6 Mois',
'newcasino.rg.self.exclusion.page.checkbox': 'En cliquant ici je comprends que cette action bloquera mon compte',
'newcasino.rg.self.exclusion.page.subtitle': "CHEZ NUMBERS GAME, NOUS CROYONS AU JEU RESPONSABLE. SI JAMAIS VOUS AVEZ L'IMPRESSION DE JOUER PLUS QUE CE QUE VOUS POUVEZ VOUS PERMETTRE, NOUS VOUS ENCOURAGEONS À FAIRE UNE PAUSE ET À ENVISAGER VOTRE AUTO-EXCLUSION. N'OUBLIEZ PAS QUE LE JEU RESPONSABLE EST LA CLÉ POUR PROFITER DES CASINOS EN LIGNE EN TOUTE SÉCURITÉ.",
'newcasino.rg.self.exclusion.page.title': 'Auto-exclusion',
'newcasino.rg.self.exclusion.success': "Votre compte est barré jusqu'à cette date:",
'newcasino.rg.take.break.page.24': '24 Heures',
'newcasino.rg.take.break.page.30': '30 Jours',
'newcasino.rg.take.break.page.7': '7 Jours',
'newcasino.rg.take.break.page.checkbox': 'En cliquant ici je comprends que cette action bloquera mon compte',
'newcasino.rg.take.break.page.subtitle': "NOUS DONNEONS LA PRIORITÉ AU JEU RESPONSABLE POUR UNE EXPÉRIENCE DE CASINO EN LIGNE SÉCURISÉE ET AGRÉABLE. SI VOUS AVEZ BESOIN D'UNE COURTE PAUSE DANS VOTRE JEU, NOTRE FONCTION 'FAIRE UNE PAUSE' VOUS PERMET DE SUSPENDRE TEMPORAIREMENT VOTRE COMPTE. SI VOUS AVEZ BESOIN D'UNE PAUSE PLUS LONGUE, N'HÉSITEZ PAS À CONSULTER NOTRE FONCTION D'AUTO-EXCLUSION.",
'newcasino.rg.take.break.page.title': 'Prendre une pause',
'newcasino.rg.take.break.success': "Votre compte est barré jusqu'à cette date:",
'newcasino.search.noresult': 'Aucun Résultat trouvé',
'newcasino.search.result.title1': 'Résultats de recherche -',
'newcasino.search.result.title2': 'item(s)',
'newcasino.session.fourOone': "Vous n'avez pas accès à cette page, veuillez vous reconnecter",
'newcasino.session.fourOone.title': 'Erreur',
'newcasino.session.inactive.msg': 'Votre session a expirée. Veuillez vous reconnecter',
'newcasino.session.inactive.title': 'Session expirée',
'newcasino.session.inactivity': "En raison d'inactivité, votre session a expirée, veuillez vous reconnecter.",
'newcasino.session.inactivity.title': 'Votre session a expirée',
'newcasino.session.maintenance': 'Le site est en cours de mise à jour, ce ne sera pas long, revenez dans quelques instants',
'newcasino.session.maintenance.title': 'Maintenance en cours',
'newcasino.sms.admin.detail.button.send': 'Envoyer',
'newcasino.sms.admin.detail.header.title.missing': '???',
'newcasino.sms.admin.detail.msg.en': 'Message en anglais',
'newcasino.sms.admin.detail.msg.en.hint': 'Taper le message en anglais',
'newcasino.sms.admin.detail.msg.fr': 'Message en français',
'newcasino.sms.admin.detail.msg.fr.hint': 'Taper le message en français',
'newcasino.sms.admin.detail.title.en': 'Description',
'newcasino.sms.admin.detail.title.en.hint': 'Taper la description en anglais',
'newcasino.sms.admin.detail.twilio': 'Réponse de Twilio',
'newcasino.sms.admin.list.add.button': 'Nouveau message',
'newcasino.sms.admin.list.col1': 'Date',
'newcasino.sms.admin.list.col2': 'Nom',
'newcasino.sms.admin.list.col3': 'Envoyé',
'newcasino.sms.admin.list.no': 'Non',
'newcasino.sms.admin.list.title': 'Messagerie texte',
'newcasino.sms.admin.list.yes': 'Oui',
'newcasino.sms.fetching.data': "Il nous est impossible d'afficher l'historique des messages Textes.",
'newcasino.sms.send.fail': "Erreur lors de la tentative d'envoi du message texte",
'newcasino.sms.send.ok': 'Message texte envoyé',
'newcasino.store.elements.cash': 'Argent',
'newcasino.store.elements.freespin': 'Tours gratuits',
'newcasino.store.elements.wager': 'Wager',
'newcasino.streamer.claim': 'Obtenir un contrat',
'newcasino.streamer.copied': 'Copié',
'newcasino.streamer.copy': 'Copier le lien',
'newcasino.streamer.deal': 'Vos contrats: {amount} - {xTime}x, {perWeek} par semaine',
'newcasino.streamer.giveaway': 'Argent pour les spectateurs: Disponible: {availableGift}',
'newcasino.streamer.info.part1': 'Bonjour Influenceur, Vous trouverez ici tous les outils nécessaires afin de donner la meilleure expérience possible à vos spectateurs.',
'newcasino.streamer.invite': 'Inviter vos {viewers}',
'newcasino.streamer.invite.part1': 'spectateurs',
'newcasino.streamer.money.to.claim': 'Contrats disponibles',
'newcasino.streamer.pending': "En attente d'approbation",
'newcasino.streamer.title': 'Influenceur',
'newcasino.streamer.vault': 'Votre Voûte',
'newcasino.streamer.vault.amount': 'Votre voûte contient',
'newcasino.success.delete.contest': 'Le concours a été supprimé',
'newcasino.success.delete.popup': 'Popup supprimer',
'newcasino.success.save.contest': 'Le concours a été sauvegardé',
'newcasino.success.save.popup': 'Popup sauvegardé',
'newcasino.success.title': 'Succès',
'newcasino.user.address.address.fn.error': 'Trop court',
'newcasino.user.address.address.fn.hint': 'Prénom',
'newcasino.user.address.address.fn.label': 'Prénom',
'newcasino.user.address.address.ln.error': 'Trop court',
'newcasino.user.address.address.ln.hint': 'Nom de famille',
'newcasino.user.address.address.ln.label': 'Nom de famille',
'newcasino.user.address.kyc.approved': 'Approuvé',
'newcasino.user.address.kyc.nophoto': 'Sans Photo',
'newcasino.user.address.kyc.pending': 'En Attente',
'newcasino.user.address.kyc.rejected': 'Rejeté',
'newcasino.user.address.kyc.unknown': 'Inconnu',
'newcasino.user.address.phonestatus.empty': 'Vide',
'newcasino.user.address.phonestatus.failed3x': 'Barré 3x',
'newcasino.user.address.phonestatus.unknown': 'Inconnu',
'newcasino.user.address.phonestatus.verif': 'Vérifié',
'newcasino.user.address.ustatus.active': 'Actif',
'newcasino.user.address.ustatus.inactive': 'Inactif',
'newcasino.user.address.ustatus.unknown': 'Inconnu',
'newcasino.user.address.utype.admin': 'Admin',
'newcasino.user.address.utype.partner': 'Partenaire',
'newcasino.user.address.utype.player': 'Joueur',
'newcasino.user.address.utype.streamer': 'Influenceur',
'newcasino.user.address.utype.unknown': 'Inconnu',
'newcasino.usermenu.deposit': 'Dépot / Retrait',
'newcasino.usermenu.logout': 'Déconnexion',
'newcasino.usermenu.profile': 'Mon Profil',
'newcasino.wallet.tx.type.0': 'Création du portefeuille',
'newcasino.wallet.tx.type.100': 'Depôt - Manuel',
'newcasino.wallet.tx.type.101': 'Depôt - Banque',
'newcasino.wallet.tx.type.102': 'Depôt - Retrait Annulé',
'newcasino.wallet.tx.type.103': 'Depôt - Points PNG Echangés',
'newcasino.wallet.tx.type.104': 'Depôt - Promo Bonus',
'newcasino.wallet.tx.type.105': 'Depôt - Promo Bonus',
'newcasino.wallet.tx.type.106': 'Depôt - Ami Bonus',
'newcasino.wallet.tx.type.110': 'Depôt - Gain',
'newcasino.wallet.tx.type.115': "Depôt - Don d'un autre joueur",
'newcasino.wallet.tx.type.120': 'Depôt - Transfert de bonus',
'newcasino.wallet.tx.type.125': 'Depôt - Creation du bonus',
'newcasino.wallet.tx.type.130': 'Depôt - Mise annulée',
'newcasino.wallet.tx.type.140': 'Depôt - Migration du système',
'newcasino.wallet.tx.type.150': 'Depôt - Paye Influenceur',
'newcasino.wallet.tx.type.200': 'Retrait - Manuel',
'newcasino.wallet.tx.type.201': 'Retrait - Banque',
'newcasino.wallet.tx.type.202': 'Retrait - Vers Bonus',
'newcasino.wallet.tx.type.210': 'Retrait - Mise',
'newcasino.wallet.tx.type.230': 'Retrait - Gain annulé',
'newcasino.wallet.tx.type.300': 'Fermeture du portefeuille',
'raf.wager.newcasino.menuleft.3.6': 'Référez un ami (Wager)',
'raf.wager.newcasino.newcasino.referafriend.money.to.claim': 'Bonus disponibles',
'raf.wager.newcasino.referafriend.claim': 'RÉCLAMER MON BONUS',
'raf.wager.newcasino.referafriend.info.part1': "Recevez un bonus de dépôt (Wager) pour chaque invitation réussie. Lorsque votre ami dépose un minimum de {minimalDeposit} et joue ce montant {wagerRequired}, vous obtenez le même montant avec une exigence de mise de {wagerRequiredRecipient}. Invitez autant d'amis que vous le souhaitez - Aucune limite. Partagez le plaisir et récoltez les récompenses dès aujourd'hui !",
'raf.wager.newcasino.referafriend.info.part3': 'Pour réclamer le bonus de dépôt correspondant, il vous suffit de cliquer sur « Réclamez ma mise » ici même sur la page Référez un ami.',
'raf.wager.newcasino.referafriend.money.claimed': 'Bonus que vous avez réclamés',
'raf.wager.newcasino.referafriend.nbr.player.wager': 'Amis qui ont complétés le requis de bonus',
'raf.wager.newcasino.referafriend.popup.text': 'Sélectionner les bonus que vous souhaitez utiliser. Si les montants sont en devises étrangères, ils seront automatiquement converti dans votre devise.',
'raf.wager.newcasino.referafriend.popup.title': 'Réclamer mon bonus',
'raf.wager.newcasino.referafriend.wager.success': "Bravo!!! Vous venez d'obtenir {money} avec un montant de Wager de {wager}.",
}

export default {
locale: 'fr-FR',
localeAntd,
messages
}
