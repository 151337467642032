/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as backendService from 'newui/Services/Backend/backendService';
import TextField from 'newui/Generic/Inputs/TextField';
import { displayErrorNotification, displayInfoNotification, isTextMin2Char } from 'newui/Utils/uiUtil';
import Button from 'newui/Generic/Inputs/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as constants from 'newui/constants.js'
import { displayPage } from 'newui/Utils/routerUtil';
import { setTheme } from 'newui/Utils/themeUtils';
import { getImageUrl } from 'newui/Utils/uiUtil';

function EditSMS(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [smsMsg, setSmsMsg] = useState();

    const [genTitle, setGenTitle] = useState(constants.STRING_EMPTY);
    const [genTitleValid, setGenTitleValid] = useState(false);

    const [genDesc, setGenDesc] = useState(constants.STRING_EMPTY);
    const [genDescValid, setGenDescValid] = useState(false);
    const [genDescFr, setGenDescFr] = useState(constants.STRING_EMPTY);
    const [genDescFrValid, setGenDescFrValid] = useState(false);

    const isNew = (params.id === undefined || params.id === null || params.id === '-1' || params.id === -1 || params.id === 'NEW')
    const intl = useIntl();

    useEffect(() => {
        if (!isNew) {
            let id = params.id;
            backendService.getSmsInformation(id, successGetData, failureGetData, navigate, location.pathname)
        } else {
            setSmsMsg({
                id: -1,
                name: constants.STRING_EMPTY,
                msg: { en: constants.STRING_EMPTY, fr: constants.STRING_EMPTY }
            })
        }
    }, []);

    function successGetData(data) {
        setSmsMsg({
            id: data.smsId,
            name: data.smsName,
            response: data.response,
            msg: { en: data.smsMsgEn, fr: data.smsMsgFr }
        })
    }

    function isTitleValid(isValid, value) {
        setGenTitleValid(isValid);
        setGenTitle(value);
    }

    function isDescriptionValid(isValid, value) {
        setGenDescValid(isValid);
        setGenDesc(value);
    }

    function isDescriptionFrValid(isValid, value) {
        setGenDescFrValid(isValid);
        setGenDescFr(value);
    }


    function failureGetData(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.sms.fetching.data', defaultMessage: 'Error trying to get Text messages information' }));
    }

    function sendSMS() {
        var smsMsgClone = JSON.parse(JSON.stringify(smsMsg));
        smsMsgClone.name = genTitle;
        smsMsgClone.msg.en = genDesc;
        smsMsgClone.msg.fr = '';
        backendService.sendSMS(smsMsgClone, successSave, failureSave, navigate, location.pathname);
    }

    function successSave(data) {
        if (data.result === 'KO') {
            displayPage(constants.ROUTE_SMS, navigate)
            displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.sms.send.fail', defaultMessage: 'Error trying to send the Text Message' }));
        } else {
            displayPage(constants.ROUTE_SMS, navigate)
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.success.title', defaultMessage: 'Success' }), intl.formatMessage({ id: 'newcasino.sms.send.ok', defaultMessage: 'Text Message Sended' }));
        }
    }

    function failureSave(data) {
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.sms.send.fail', defaultMessage: 'Error trying to send the Text Message' }));
    }

    if (smsMsg) {
        return (
            <>
                <div className={style.master_page_container}>

                    <div className={style.topMainDiv}>
                        <div>
                            <div className={style.grid100}>
                                <div className={style.case1TopText2}>{genTitle ? genTitle : intl.formatMessage({ id: 'newcasino.sms.admin.detail.header.title.missing', defaultMessage: '???' })}</div>
                                <div className={style.case1TopText3}>
                                    <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                                </div>
                                <div className={style.grid50_50}>
                                    <TextField readOnly={!isNew} validateOnStart={!isNew} label={intl.formatMessage({ id: 'newcasino.sms.admin.detail.title.en', defaultMessage: 'Description' })} mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isTitleValid} values={smsMsg.name ? [smsMsg.name] : [constants.STRING_EMPTY]} hint={intl.formatMessage({ id: 'newcasino.sms.admin.detail.title.en.hint', defaultMessage: 'Type the description in English' })} />
                                </div>
                                <div className={style.grid50_50}>
                                    <TextField readOnly={!isNew} maxLength={320} validateOnStart={!isNew} rows='5' type='textarea' mandatory='true' showCheck='true' validator={isTextMin2Char} validatorInformer={isDescriptionValid} values={smsMsg.msg.en ? [smsMsg.msg.en] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.sms.admin.detail.msg.en', defaultMessage: 'Description English' })} hint={intl.formatMessage({ id: 'newcasino.sms.admin.detail.msg.en.hint', defaultMessage: 'Type the message in English' })} />
                                </div>
                                {!isNew &&
                                    <div className={style.grid50_50s}>
                                        <TextField readOnly={true} validateOnStart={false} rows='30' type='textarea' mandatory='false' showCheck='false' validator={isTextMin2Char} values={smsMsg.response ? [smsMsg.response] : [constants.STRING_EMPTY]} label={intl.formatMessage({ id: 'newcasino.sms.admin.detail.twilio', defaultMessage: 'Twilio Response' })} hint='' />
                                    </div>
                                }

                                {isNew &&
                                    <div className={style.grid50_50b}>
                                        <div className={style.grid50_50b}>
                                            <Button colorStyle='light' active={(genTitleValid && genDescValid)} width='120px' value={intl.formatMessage({ id: 'newcasino.sms.admin.detail.button.send', defaultMessage: 'Send' })} marginTop='24px' onClick={sendSMS} />
                                        </div>
                                        <div />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default EditSMS;